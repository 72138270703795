/**
 * Component displays the UI for Actual/Plan years selections
 *
 */

import React from 'react';
import { Utilities } from '../../helpers';
import {
    Stack,
    DatePicker,
    DayOfWeek,
    IDatePickerStrings,
    mergeStyleSets,
} from '@fluentui/react';
import { useAppSelector } from '../../store';
import { selectFileSharingSettings } from '../../store/selectors/auth';
import { FilesSharing } from 'nextcloud-api';

const dayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],

    shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],

    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    monthPickerHeaderAriaLabel: '{0}, select to change the year',
    yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

export const getMinAllowedExpirationDate = (
    fileSharingSettings: FilesSharing,
): Date => {
    if (
        fileSharingSettings.ExpireDateEnabled &&
        fileSharingSettings.ExpireDateEnforced &&
        fileSharingSettings.ExpireDateDays > 0
    ) {
        const expireDateDays = Number(fileSharingSettings.ExpireDateDays);
        const isValidExpireDateDay = !isNaN(expireDateDays);

        const minDate = new Date();
        const daysToAdd = isValidExpireDateDay ? expireDateDays : 1;
        minDate.setDate(minDate.getDate() + daysToAdd);

        return minDate;
    }

    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
};

export const getMaxAllowedExpirationDate = (
    fileSharingSettings: FilesSharing,
): Date | null => {
    if (
        !fileSharingSettings.ExpireDateEnabled ||
        !fileSharingSettings.ExpireDateEnforced ||
        fileSharingSettings.ExpireDateDays === 0
    ) {
        return null;
    }

    const expireDateDays = Number(fileSharingSettings.ExpireDateDays);
    const isValidExpireDateDay = !isNaN(expireDateDays);

    const maxDate = new Date();
    const daysToAdd = isValidExpireDateDay ? expireDateDays : 1;
    maxDate.setDate(maxDate.getDate() + daysToAdd);

    return maxDate;
};

const controlClass = mergeStyleSets({
    control: {
        width: '100%',
    },
});

type Props = {
    date: Date;
    onDateChanged: (date: Date) => void;
    disabledContent?: boolean | 'true' | 'false';
    disabled?: boolean;
};

const DateTime = (props: Props): JSX.Element => {
    const filesSharingSettings = useAppSelector(selectFileSharingSettings);

    const onSelectDate = (date: Date | null | undefined): void => {
        const dt = new Date(date);
        props.onDateChanged(dt);
    };

    const minDate = filesSharingSettings
        ? getMinAllowedExpirationDate(filesSharingSettings)
        : null;

    const maxDate = filesSharingSettings
        ? getMaxAllowedExpirationDate(filesSharingSettings)
        : null;

    return (
        <Stack
            horizontal
            verticalAlign="start"
            aria-disabled={props.disabledContent}
            tokens={{ childrenGap: 5 }}
        >
            <DatePicker
                disabled={props.disabled}
                className={controlClass.control}
                firstDayOfWeek={DayOfWeek.Monday}
                strings={dayPickerStrings}
                formatDate={Utilities.formatLocaleDate}
                showMonthPickerAsOverlay={true}
                value={props.date}
                onSelectDate={onSelectDate}
                minDate={minDate}
                maxDate={maxDate}
            />
        </Stack>
    );
};

export default DateTime;
