import React, { useEffect } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { Location } from 'history';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Logger } from './helpers/logger';
import { appActions } from './store/actions/app/actions';
import { uiActions } from './store/actions/ui/actions';
import { Footer, Waiting, NotificationBar } from './components/common';
import { UserStorageHelper } from './helpers';
import {
    LoginPage,
    SettingsLayout,
    NextcloudExplorerPage,
    DndSharePage,
    SharePage,
} from './pages';
import { PrivateRoute } from './routing';

const transformLocation = (inputLocation: Location): Location => {
    Logger.info(
        `transformLocation called with location.Search == 
        ${inputLocation.search}; 
        location = ${inputLocation}`,
    );
    const queryStringParsed = queryString.parse(inputLocation.search);
    Logger.info(`queryStringParsed.page == ${queryStringParsed.page}`);
    Logger.info(`inputLocation.hash == ${inputLocation.hash}`);
    Logger.info(
        // eslint-disable-next-line max-len
        `UserStorageHelper.storeHasUser() == ${UserStorageHelper.storeHasUser()}`,
    );

    if (inputLocation.hash == '#/login') {
        return {
            ...inputLocation,
            pathname: '/login',
        };
    }

    if (inputLocation.hash == '#/settings') {
        return {
            ...inputLocation,
            pathname: '/settings',
        };
    }

    if (inputLocation.hash == '#/settings/license') {
        return {
            ...inputLocation,
            pathname: '/settings/license',
        };
    }

    if (inputLocation.hash == '#/settings/general') {
        return {
            ...inputLocation,
            pathname: '/settings/general',
        };
    }

    if (inputLocation.hash == '#/share') {
        return {
            ...inputLocation,
            pathname: '/share',
        };
    }

    if (inputLocation.hash === '#/explorer') {
        return {
            ...inputLocation,
            pathname: '/explorer',
        };
    }

    return {
        ...inputLocation,
        pathname:
            '/' +
            (queryStringParsed.page === '' ||
            queryStringParsed.page === null ||
            queryStringParsed.page === undefined
                ? 'main'
                : queryStringParsed.page),
    };
};

const App = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        Logger.info('Initialized the application mainpage');
        Logger.debug('props.location=' + props.location);
        dispatch(uiActions.initializeAddinUi(props.location));
        dispatch(appActions.initializeAddin(props.location));
    }, []);

    return (
        <div className="wrapper">
            <Router>
                <NotificationBar />
                <div className="main">
                    <Route
                        render={() => (
                            <Switch
                                location={transformLocation(props.location)}
                            >
                                <Route path="/login" component={LoginPage} />
                                <PrivateRoute
                                    path="/share"
                                    component={SharePage}
                                />
                                <PrivateRoute
                                    path="/dnd-share"
                                    component={DndSharePage}
                                />
                                <PrivateRoute
                                    path="/settings"
                                    component={SettingsLayout}
                                />
                                <PrivateRoute
                                    path="/explorer"
                                    component={NextcloudExplorerPage}
                                />
                                <Redirect from="*" to="/share" />
                            </Switch>
                        )}
                    />
                </div>
                <Footer />
                <Waiting />
            </Router>
        </div>
    );
};

export default App;
