import { RootState } from '..';

export const selectIsFetching = (state: RootState) =>
    state.appReducer.longOperation.isFetched;

export const selectStubUrl = (state: RootState) =>
    state.appReducer.appConfig.stubUrl;

export const selectMicrosoftAppId = (state: RootState) =>
    state.appReducer.appConfig.teamsApp.MicrosoftAppId;

export const selectServerFiles = (state: RootState) =>
    state.appReducer.serverFiles;

export const selectFilesToUpload = (state: RootState) =>
    state.appReducer.filesToUpload;

export const selectDestinationFolder = (state: RootState) =>
    state.appReducer.destinationFolder;
