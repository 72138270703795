import React, { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import {
    Stack,
    Text,
    IStackTokens,
    ITextStyles,
    mergeStyleSets,
} from '@fluentui/react';
import { Checkbox } from '../components/common/checkbox';
// eslint-disable-next-line max-len
import { DatePickerWithOptions } from '../components/common/datePickerWithOptions';
import { useAppDispatch, useAppSelector } from '../store';
import { ProgressDialog, WarningDialog } from '../components/dialogs';
import { useFormValidationDialog, useUnmount } from '../helpers/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
    getIntialPublicShareValues,
    publicShareCacheRefBox,
} from '../form-loaders/publicShare';
import { Logger } from '../helpers';
import { WizardFooter } from '../components/wizard/footer';
import { PublicShareData, publicShareSchema } from '../schemas/shareFile';
import {
    shareDndFilesThunk,
    shareServerFileThunk,
} from '../store/actions/app/thunks';
import { selectTranslate } from '../store/selectors/ui';
import { ShareTypePageVariant } from './organizationShare';
import { selectDestinationFolder, selectStubUrl } from '../store/selectors/app';
import {
    selectFileSharingSettings,
    selectTeamsPathUploadFiles,
    selectUser,
} from '../store/selectors/auth';
import {
    setProgressSize,
    addUploadedSize,
} from '../store/actions/app/actionCreators';
import { reduceFileSize } from '../helpers/file';
import { SideButtonTextField } from '../components/common/sideButtonTextField';
import { passwordApi } from 'nextcloud-api';

const containerTokens: IStackTokens = { childrenGap: 20 };

const pageDescriptionTextStyles: ITextStyles = {
    root: { lineHeight: 20, color: '#98908C' },
};

const expirationDateContainerTokens: IStackTokens = { childrenGap: 8 };

const expirationDateLabelTextStyles: ITextStyles = {
    root: { lineHeight: 20, fontWeight: 600 },
};

const passwordContainerTokens: IStackTokens = { childrenGap: 8 };

const shareWithoutPasswordContainerTokens: IStackTokens = { childrenGap: 8 };

export type PublicSharePageProps = {
    variant: ShareTypePageVariant;
};

export const PublicSharePage = ({
    variant,
}: PublicSharePageProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const translate = useAppSelector(selectTranslate);

    const stubUrl = useAppSelector(selectStubUrl);
    const user = useAppSelector(selectUser);

    const fileSharingSettings = useAppSelector(selectFileSharingSettings);
    const destinationFolder = useAppSelector(selectDestinationFolder);
    const defaultUploadFolder = useAppSelector(selectTeamsPathUploadFiles);

    const {
        watch,
        setValue,
        handleSubmit,
        formState: { isLoading, errors },
    } = useForm<PublicShareData>({
        defaultValues: getIntialPublicShareValues,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(publicShareSchema),
        criteriaMode: 'firstError',
    });

    const password = isLoading ? '' : watch('password');
    const expirationDate = isLoading ? null : watch('expirationDate');
    const shareWithoutPassword = isLoading
        ? false
        : watch('shareWithoutPassword');

    useUnmount(() =>
        publicShareCacheRefBox.setValue({
            password,
            expirationDate,
            shareWithoutPassword,
        }),
    );

    const { errorToDisplay, showValidationDialog, setShowValidationDialog } =
        useFormValidationDialog(errors);

    const [progressDialogHidden, setProgressDialogHidden] = useState(true);

    const onUpload = async (
        {
            files,
            password,
            shareWithoutPassword,
            expirationDate,
        }: PublicShareData,
        e: FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        e.preventDefault();
        Logger.info('onUpload called');

        const finalPassword = shareWithoutPassword ? undefined : password;

        if (variant === ShareTypePageVariant.Dnd) {
            const uploadFolder = destinationFolder ?? defaultUploadFolder;
            const totalSize = files.reduce(reduceFileSize, 0);

            setProgressDialogHidden(false);
            dispatch(setProgressSize(totalSize));
            await dispatch(
                shareDndFilesThunk({
                    files,
                    folder: uploadFolder,
                    password: finalPassword,
                    expirationDate,
                    onUploadProgress: (progress) =>
                        dispatch(addUploadedSize(progress)),
                }),
            );
            return;
        }

        const file = files[0];
        if (!file) {
            return;
        }

        dispatch(
            shareServerFileThunk({
                path: file.path,
                password: finalPassword,
                expirationDate,
            }),
        );
    };

    const onExpirationDateChange = (date: Date | null): void =>
        setValue('expirationDate', date);

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>): void =>
        setValue('password', e.currentTarget.value);

    const onShareWithoutPasswordChange = (
        e: ChangeEvent<HTMLInputElement>,
    ): void => {
        if (!('checked' in e.currentTarget)) {
            return;
        }
        setValue('shareWithoutPassword', e.currentTarget.checked);
    };

    const hideValidationDialog = () => setShowValidationDialog(false);

    const expirationDateLabelStyles = useMemo(() => {
        if (isLoading) {
            return mergeStyleSets(expirationDateLabelTextStyles, {
                root: { color: '#A19F9D' },
            });
        }

        return expirationDateLabelTextStyles;
    }, [isLoading]);

    const isPasswordEnforced =
        Boolean(fileSharingSettings) && fileSharingSettings.PasswordEnabled;

    const generatePassword = async () => {
        const passwordRes = await passwordApi.generatePassword(stubUrl, user);
        if (!passwordRes.success) {
            return;
        }
        setValue('password', passwordRes.result.ocs.data.password);
        setValue('shareWithoutPassword', false);
    };

    return (
        <>
            <form onSubmit={handleSubmit(onUpload)}>
                <Stack tokens={containerTokens}>
                    <Text styles={pageDescriptionTextStyles}>
                        {translate('public_share_description')}
                    </Text>
                    <Stack tokens={expirationDateContainerTokens}>
                        <Text
                            variant="medium"
                            styles={expirationDateLabelStyles}
                        >
                            {translate('sharefiles_date_label')}
                        </Text>
                        <DatePickerWithOptions
                            disabled={isLoading}
                            date={expirationDate}
                            onDateChange={onExpirationDateChange}
                        />
                    </Stack>
                    <Stack tokens={passwordContainerTokens}>
                        <SideButtonTextField
                            label={translate('sharefiles_pswd_header')}
                            value={password}
                            disabled={shareWithoutPassword || isLoading}
                            onChange={onPasswordChange}
                            buttonText={translate('pswd_dialog_generate')}
                            onButtonClick={generatePassword}
                        />
                        <Stack
                            horizontal={true}
                            tokens={shareWithoutPasswordContainerTokens}
                        >
                            <Checkbox
                                disabled={isPasswordEnforced || isLoading}
                                color="#F36928"
                                checked={shareWithoutPassword}
                                onChange={onShareWithoutPasswordChange}
                            />
                            <Text>Share without password</Text>
                        </Stack>
                    </Stack>
                </Stack>
                <WizardFooter />
            </form>
            <WarningDialog
                hidden={!showValidationDialog}
                subText={errorToDisplay?.message}
                icon="ErrorBadge"
                color="red"
                onDismiss={hideValidationDialog}
            />
            {variant === ShareTypePageVariant.Dnd && (
                <ProgressDialog
                    hidden={progressDialogHidden}
                    onDismiss={setProgressDialogHidden}
                    title={translate('progress_header')}
                    subText={translate('progress_count')}
                />
            )}
        </>
    );
};
