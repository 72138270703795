import { ReduxAction, createReduxAction } from '../../utils';
import { ConversationActionType } from './actionTypes';
import { ChatMember } from '../../../services/statusSync/msGraphApi';
import { app } from '@microsoft/teams-js';
import { ShareContext } from '../../reducers/conversation/reducer';
import {
    ApiResponse,
    OCSOkResponseBody,
    SearchUserIdsData,
} from 'nextcloud-api';

export type ConversationAction =
    | SetTeamsContext
    | SetChatMembers
    | SetIsExternalMember
    | SetShareContext
    | SetUserIdsResponse;

export type SetTeamsContextPayload = app.Context;

export type SetTeamsContext = ReduxAction<
    ConversationActionType.SetTeamsContext,
    SetTeamsContextPayload
>;

export const setTeamsContext = (
    payload: SetTeamsContextPayload,
): SetTeamsContext =>
    createReduxAction(ConversationActionType.SetTeamsContext, payload);

export type SetChatMembersPayload = {
    chatMembers: ChatMember[];
};

export type SetChatMembers = ReduxAction<
    ConversationActionType.SetChatMembers,
    SetChatMembersPayload
>;

export const setChatMembers = (
    payload: SetChatMembersPayload,
): SetChatMembers =>
    createReduxAction(ConversationActionType.SetChatMembers, payload);

type SetIsExternalMemberPayload = {
    isExternalMember: boolean | null;
};

type SetIsExternalMember = ReduxAction<
    ConversationActionType.SetIsExternalMember,
    SetIsExternalMemberPayload
>;

export const setIsExternalMember = (
    isExternalMember: boolean | null,
): SetIsExternalMember =>
    createReduxAction(ConversationActionType.SetIsExternalMember, {
        isExternalMember,
    });

type SetShareContextPayload = {
    shareContext: ShareContext;
};

type SetShareContext = ReduxAction<
    ConversationActionType.SetShareContext,
    SetShareContextPayload
>;

export const setShareContext = (shareContext: ShareContext): SetShareContext =>
    createReduxAction(ConversationActionType.SetShareContext, {
        shareContext,
    });

type SetUserIdsResponsePayload = {
    query: string;
    userIdsResponse: ApiResponse<OCSOkResponseBody<SearchUserIdsData>>;
};

export type SetUserIdsResponse = ReduxAction<
    ConversationActionType.SetUserIdsResponse,
    SetUserIdsResponsePayload
>;

export const setUserIdsResponse = (
    query: string,
    userIdsResponse: ApiResponse<OCSOkResponseBody<SearchUserIdsData>>,
): SetUserIdsResponse =>
    createReduxAction(ConversationActionType.SetUserIdsResponse, {
        query,
        userIdsResponse,
    });
