import produce from 'immer';
import { getTranslation } from '../../../helpers/translation';
import { uiConstants } from '../../actions/ui/actionTypes';

const initialState = {
    lang: localStorage.getItem('lang') || 'en',
    translate: getTranslation('en'),
    pageMode: '',
};

export function uiReducer(state = initialState, action) {
    switch (action.type) {
        case uiConstants.UI_INITIALIZE_ADDIN:
            return produce(state, (draft) => {
                draft.translate = getTranslation(
                    action.payload.displayLanguage,
                );
                draft.lang = action.payload.displayLanguage;
                draft.pageMode = action.payload.pageMode;
            });
        case uiConstants.APP_CHANGE_LANG:
            return produce(state, (draft) => {
                draft.lang = action.payload.displayLanguage;
                draft.translate = getTranslation(
                    action.payload.displayLanguage,
                );
            });
        case uiConstants.UI_SET_PAGE_MODE:
            return produce(state, (draft) => {
                draft.pageMode = action.payload.pageMode;
            });
        default:
            return state;
    }
}
