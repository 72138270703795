/**
 * Core application UI actions types
 */

export const uiConstants = {
    UI_INITIALIZE_ADDIN: 'app.UI_INITIALIZE_ADDIN',
    UI_CHANGE_HEADER_STATE: 'app.UI_CHANGE_HEADER_STATE',
    APP_CLEAR_NOTIFICATION: 'app.APP_CLEAR_NOTIFICATION',
    APP_SHOW_NOTIFICATION: 'app.APP_SHOW_NOTIFICATION',
    APP_CHANGE_LANG: 'app.APP_CHANGE_LANG',
    UI_SET_PAGE_MODE: 'ui.SET_PAGE_MODE',
} as const;
