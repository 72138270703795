import { Stack, PrimaryButton } from '@fluentui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FormEvent, useEffect, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { appStyles } from '../../helpers';
import { useAppSelector, useAppDispatch } from '../../store';
import { createFolderThunk } from '../../store/actions/app/thunks';
import { selectTranslate } from '../../store/selectors/ui';
import { ControlledTextField } from './controls/controlledTextField';
import { createFolderFormDataSchema } from '../../schemas/createFolder';
import { CreateFolderData } from '../../model';

type Props = {
    pathForCreation: string;
    onCancel: (event: MouseEvent<HTMLButtonElement> | null) => void;
};

export const CreateFolderForm = ({ pathForCreation, onCancel }: Props) => {
    const translate = useAppSelector(selectTranslate);
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        setError,
    } = useForm<CreateFolderData>({
        defaultValues: {
            path: pathForCreation,
            folderName: '',
        },
        resolver: zodResolver(createFolderFormDataSchema),
        mode: 'onSubmit',
    });

    const dispatch = useAppDispatch();
    const onSubmitHandler = (
        data: CreateFolderData,
        _e: FormEvent<HTMLFormElement>,
    ) => {
        onCancel(null);
        dispatch(createFolderThunk(data));
    };

    useEffect(() => {
        const err = errors[''];
        if (!err) {
            return;
        }
        setError('folderName', err);
    }, [errors['']]);

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Stack tokens={{ childrenGap: '1rem' }}>
                <ControlledTextField
                    control={control}
                    {...register('folderName')}
                    label={translate('enter_name')}
                />
                <Stack
                    horizontal={true}
                    horizontalAlign="end"
                    tokens={{ childrenGap: '1rem' }}
                >
                    <PrimaryButton
                        type="submit"
                        styles={appStyles.primaryButton}
                    >
                        {translate('button_create')}
                    </PrimaryButton>
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        onClick={onCancel}
                    >
                        {translate('button_cancel')}
                    </PrimaryButton>
                </Stack>
            </Stack>
        </form>
    );
};
