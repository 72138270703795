import React from 'react';
import { useState } from 'react';
import { isObject } from './utils';
import { WizardContextValue, WizardStepMeta } from './wizard.model';
import { useWizard } from './hook';
import { WizardContext } from './context';

export type WizardProviderProps = {
    stepMetaArr: WizardStepMeta[];
    children: JSX.Element | JSX.Element[];
};

const WizardProvider = ({
    stepMetaArr,
    children,
}: WizardProviderProps): JSX.Element => {
    const [wizardStep, setWizardStep] = useState<number>(0);

    const prev = async () => {
        if (!wizardStep) {
            return;
        }
        setWizardStep((prevPos) => prevPos - 1);
    };

    const next = async () => {
        const currentStepMeta = stepMetaArr[wizardStep];

        const canMoveToNext =
            !currentStepMeta.canMoveToNext ||
            (currentStepMeta && (await currentStepMeta.canMoveToNext()));

        if (!canMoveToNext) {
            return;
        }

        let nextStep = wizardStep + 1;
        let nextStepMeta = stepMetaArr[nextStep];

        if (!nextStepMeta) {
            return;
        }

        while (
            !nextStepMeta?.canSkip ||
            (nextStepMeta?.canSkip && (await nextStepMeta.canSkip()))
        ) {
            const isLastPage = nextStep === stepMetaArr.length - 1;
            if (isLastPage) {
                break;
            }

            nextStepMeta = stepMetaArr[++nextStep];
        }

        if (wizardStep === nextStep) {
            return;
        }
        setWizardStep(nextStep);
    };

    const maxWizardStep = stepMetaArr.length - 1;

    const setWizardStepWrapper = (
        position: number | ((prevPosition: number) => number),
    ): void => {
        const nextStep =
            typeof position === 'number' ? position : position(wizardStep);

        const isInvalidStep = nextStep < 0 || nextStep > maxWizardStep;
        if (isInvalidStep) {
            return;
        }

        setWizardStep(nextStep);
    };

    const contextVallue: WizardContextValue = {
        wizardStep: wizardStep,
        maxWizardStep,
        isFirstStep: wizardStep === 0,
        isLastStep: wizardStep === maxWizardStep,
        activeStepTag: stepMetaArr[wizardStep].tag,
        setWizardStep: setWizardStepWrapper,
        prev,
        next,
    };

    return (
        <WizardContext.Provider value={contextVallue}>
            {children}
        </WizardContext.Provider>
    );
};

type WizardStepsProps = {
    children: JSX.Element[];
};

export const WizardSteps = ({ children }: WizardStepsProps): JSX.Element => {
    const { maxWizardStep, wizardStep } = useWizard();

    const objectChildren = children.filter(isObject);

    if (maxWizardStep !== objectChildren.length - 1) {
        const stepCount = maxWizardStep + 1;
        throw new Error(
            `${WizardSteps.name} component must have ${stepCount} children`,
        );
    }

    return <>{objectChildren[wizardStep] ?? null}</>;
};

export const Wizard = {
    Provider: WizardProvider,
    Steps: WizardSteps,
};
