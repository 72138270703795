import { z } from 'zod';
import { dndFilesSchema } from './shared';
import { expirationDateRefinement, passwordRefinement } from './refinements';

export type PublicShareData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    files: any[];
    password?: string;
    expirationDate?: Date | null;
    shareWithoutPassword?: boolean;
};

export const publicShareCacheSchema = z.object({
    password: z.string().optional(),
    expirationDate: z.date().nullable().optional(),
    shareWithoutPassword: z.boolean().optional(),
});

export type PublicShareCacheData = z.infer<typeof publicShareCacheSchema>;

export const publicShareSchema: z.ZodSchema<PublicShareData> = z.object({
    files: dndFilesSchema,
    password: z.string().optional().superRefine(passwordRefinement),
    expirationDate: z
        .date()
        .optional()
        .nullable()
        .superRefine(expirationDateRefinement),
    shareWithoutPassword: z.boolean().optional(),
}) as z.ZodSchema<PublicShareData>;
