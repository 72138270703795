import { store } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';
import locals from './locals/lang.json';

export const getTranslation = (lang: string) => (key: string) => {
    if (!locals[key]) return key;
    const translation = locals[key][lang]
        ? locals[key][lang]
        : locals[key]['en'];
    if (translation === null || translation === undefined) {
        return key;
    }
    return translation.text;
};

export const getStoreTranslation = (key: string): string => {
    const translate = selectTranslate(store.getState());
    return translate(key) ?? '';
};

export interface IAvailableLanguage {
    key: string;
    lang: string;
}

export const availableLanguages: IAvailableLanguage[] = [
    {
        key: 'en',
        lang: 'English',
    },
];
