import React, { CSSProperties } from 'react';
import { Stack, Text, FontIcon } from '@fluentui/react';
import { MouseEventHandler } from 'react';
import { appStyles } from '../../helpers';

const iconStyle: CSSProperties = {
    color: '#000000',
    fontSize: 16,
    fontWeight: 500,
};

type Props = {
    text: string;
    onBackButtonClick: MouseEventHandler<HTMLElement>;
};

export const PageHeadingWithBackButton = ({
    text,
    onBackButtonClick,
}: Props): JSX.Element => {
    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={appStyles.globalStyles.settingsHeaderLayout}
        >
            <Stack
                style={{ width: 20, cursor: 'pointer' }}
                onClick={onBackButtonClick}
            >
                <FontIcon
                    iconName="Back"
                    style={iconStyle}
                    className={appStyles.globalStyles.settingsBack}
                />
            </Stack>
            <Stack horizontalAlign="stretch">
                <Text className={appStyles.globalStyles.settingsTitle}>
                    {text}
                </Text>
            </Stack>
            <Stack style={{ width: 20 }}></Stack>
        </Stack>
    );
};
