import React, { useState } from 'react';
import {
    IContextualMenuItem,
    IContextualMenuProps,
    IconButton,
    Dialog,
    DialogType,
} from '@fluentui/react';
import { appStyles } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    openDownloadLinkThunk,
    deleteFileByPathThunk,
} from '../../../store/actions/app/thunks';
import { ConfirmDialog } from '../../common/confirmDialog';
import { FileType } from '../../../model';
import { EditFilenameForm } from '../../form/editFilenameForm';
import { selectTranslate } from '../../../store/selectors/ui';

type FileDropdownButtonProps = {
    filePath: string;
    type: FileType;
};

const renderNothing = (): null => null;

export const FileDropdownButton = ({
    filePath,
    type,
}: FileDropdownButtonProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const translate = useAppSelector(selectTranslate);

    const onDownloadClick = () => {
        dispatch(openDownloadLinkThunk(filePath));
    };

    const [isEditHidden, setIsEditHidden] = useState(true);
    const [isDeleteConfirmHidden, setIsDeleteConfirmHidden] = useState(true);

    const onDeleteConfirmClick: (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem,
    ) => boolean | void = () => {
        closeConfirmDeleteDialog();
        dispatch(deleteFileByPathThunk(filePath));
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'editFile',
                text: translate('rename'),
                iconProps: { iconName: 'Edit' },
                onClick: () => setIsEditHidden(false),
            },
            ...(type !== 'directory' && [
                {
                    key: 'downloadFile',
                    text: translate('download'),
                    disabled: true,
                    onRender: renderNothing,
                    iconProps: { iconName: 'Download' },
                    onClick: onDownloadClick,
                },
            ]),
            {
                key: 'deleteFile',
                text: translate('delete'),
                iconProps: {
                    iconName: 'Delete',
                },
                onClick: () => setIsDeleteConfirmHidden(false),
            },
        ],
        directionalHintFixed: true,
    };

    const closeEditDialog = () => setIsEditHidden(true);
    const closeConfirmDeleteDialog = () => setIsDeleteConfirmHidden(true);

    return (
        <>
            <IconButton
                styles={{
                    iconHovered: {
                        color: 'black',
                    },
                }}
                menuProps={menuProps}
                onRenderMenuIcon={renderNothing}
                iconProps={{ iconName: 'More' }}
            />
            <Dialog
                styles={appStyles.DialogStyle}
                hidden={isEditHidden}
                onDismiss={closeEditDialog}
                dialogContentProps={{ type: DialogType.close }}
            >
                <EditFilenameForm
                    initialPath={filePath}
                    onCancel={closeEditDialog}
                />
            </Dialog>
            <ConfirmDialog
                text={translate('confirm_delete_item')}
                hidden={isDeleteConfirmHidden}
                onConfirm={onDeleteConfirmClick}
                onDismiss={closeConfirmDeleteDialog}
                onClose={closeConfirmDeleteDialog}
            />
        </>
    );
};
