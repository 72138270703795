import { createReduxAction } from '../../utils';
import { MsalActionType } from './actionTypes';
import { AuthenticationResult } from '@azure/msal-browser';

export type MsalAction =
    | ReturnType<typeof setMsalAuthResult>
    | ReturnType<typeof setShowMsLoginDialog>;

export const setMsalAuthResult = (payload: AuthenticationResult | null) =>
    createReduxAction(MsalActionType.SetAuthResult, payload);

export const setShowMsLoginDialog = (showMsLoginDialog: boolean) =>
    createReduxAction(MsalActionType.SetShowDialog, { showMsLoginDialog });
