import { z } from 'zod';
import { getStoreTranslation } from '../helpers/translation';
import {
    destinationFolderRefinement,
    expirationDateRefinement,
} from './refinements';

export const filesSchema = z
    .array(z.any(), {
        errorMap(error, _context) {
            if (error.code === 'too_small') {
                return {
                    message: getStoreTranslation(
                        'main_page_no_attach_selected',
                    ),
                };
            }

            return {
                message: error.message,
            };
        },
    })
    .length(1);

export const dndFilesSchema = z
    .array(z.any(), {
        errorMap(error, _context) {
            if (error.code === 'too_small') {
                return {
                    message: getStoreTranslation(
                        'main_page_no_attach_selected',
                    ),
                };
            }

            return {
                message: error.message,
            };
        },
    })
    .min(1);

export const expirationDateSchema = z
    .date()
    .optional()
    .superRefine(expirationDateRefinement);

export const destinationFolderSchema = z
    .object({
        content: z.instanceof(ArrayBuffer).optional(),
    })
    .nullable()
    .superRefine(destinationFolderRefinement);
