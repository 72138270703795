import React, { CSSProperties, useState } from 'react';
import { Stack } from '@fluentui/react';
import DateTime from './datetime';
import { FilesSharing } from 'nextcloud-api';
import { useAppSelector } from '../../store';
import { selectFileSharingSettings } from '../../store/selectors/auth';
import { TextRadio } from './textRadio';

enum DateOption {
    None = 'none',
    Custom = 'custom',
    OneDay = '1_day',
    ThreeDays = '3_days',
    Week = 'week',
}

const dateOptionToText = {
    [DateOption.None]: 'None',
    [DateOption.Custom]: 'Custom',
    [DateOption.OneDay]: '1 day',
    [DateOption.ThreeDays]: '3 days',
    [DateOption.Week]: '1 week',
} as const satisfies Record<DateOption, string>;

const dateOptionToDayIncrease = {
    [DateOption.OneDay]: 1,
    [DateOption.ThreeDays]: 3,
    [DateOption.Week]: 7,
} as const satisfies Record<
    Exclude<DateOption, DateOption.Custom | DateOption.None>,
    number
>;

const getDateForDateOption = (
    option: Exclude<DateOption, DateOption.Custom>,
): Date => {
    const daysToAdd = dateOptionToDayIncrease[option];

    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);

    return date;
};

const shouldDisableDateOption = (
    dateOption: DateOption,
    fileSharingSettings: FilesSharing,
) => {
    if (dateOption === DateOption.Custom) {
        return false;
    }

    const isExpirationDateEnforced =
        fileSharingSettings.ExpireDateEnabled &&
        fileSharingSettings.ExpireDateEnforced;

    return (
        (isExpirationDateEnforced && dateOption === DateOption.None) ||
        (isExpirationDateEnforced && fileSharingSettings.ExpireDateDays !== 0)
    );
};

const gapEightPxTokens = {
    childrenGap: 8,
} as const;

const radioContainerStyle: CSSProperties = { overflowX: 'auto' };

type Props = {
    date: Date | null;
    disabled?: boolean;
    onDateChange: (date: Date | null) => void;
};

export const DatePickerWithOptions = ({
    date,
    onDateChange,
    disabled,
}: Props): JSX.Element => {
    const fileSharingSettings = useAppSelector(selectFileSharingSettings);
    const [checkedRadio, setCheckedRadio] = useState(DateOption.Custom);

    if (!fileSharingSettings) {
        return null;
    }

    const onPickerDateChange = (date: Date): void => {
        onDateChange(date);
        setCheckedRadio(DateOption.Custom);
    };

    const toTextRadioElement = (option: DateOption): JSX.Element => {
        const isDisabled =
            disabled || shouldDisableDateOption(option, fileSharingSettings);

        const onChange = (): void => {
            setCheckedRadio(option);

            switch (option) {
                case DateOption.Custom: {
                    return;
                }
                case DateOption.None: {
                    return onDateChange(null);
                }
                default: {
                    onDateChange(getDateForDateOption(option));
                }
            }
        };

        return (
            <TextRadio
                key={option}
                name={option}
                checked={option === checkedRadio}
                disabled={isDisabled}
                text={dateOptionToText[option]}
                onChange={onChange}
            />
        );
    };

    return (
        <Stack tokens={gapEightPxTokens}>
            <Stack
                style={radioContainerStyle}
                horizontal={true}
                tokens={gapEightPxTokens}
            >
                {Object.values(DateOption).map(toTextRadioElement)}
            </Stack>
            <DateTime
                disabled={disabled}
                date={date}
                onDateChanged={onPickerDateChange}
            />
        </Stack>
    );
};
