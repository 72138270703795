import {
    PublicClientApplication,
    AccountInfo,
    AuthenticationResult,
    LogLevel,
    // LogLevel,
} from '@azure/msal-browser';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../..';
import { selectMicrosoftAppId } from '../../selectors/app';
import {
    startFetch,
    OperationId,
    stopFetch,
    showNotification,
} from '../app/actionCreators';
import { setMsalAuthResult, setShowMsLoginDialog } from './actions';
import { batch } from 'react-redux';
import { storeKeys } from '../../../helpers';
import { msalRefBox } from '../../../services/statusSync/msalRefBox';
import { AppErrorCode, NotificationStatus } from '../app/actionTypes';
// eslint-disable-next-line max-len
import {
    authenticateMsTeams,
    authenticateMsTeamsInteractively,
} from '../../../services/statusSync/authentication';
import { selectTranslate } from '../../selectors/ui';
import { selectTeamsContext } from '../../selectors/conversation';

export const setMsalAuthResultThunk = (
    authResult: AuthenticationResult | null,
): ThunkAction<void, RootState, unknown, AnyAction> =>
    function thunk(dispatch) {
        const msal = msalRefBox.getValue();
        if (!msal) {
            return;
        }

        msal.setActiveAccount(authResult?.account ?? null);
        dispatch(setMsalAuthResult(authResult));
    };

// TODO: remove
export const setMsalActiveAccountThunk = (
    account: AccountInfo,
): ThunkAction<void, RootState, unknown, AnyAction> =>
    async function thunk(dispatch, _getState): Promise<void> {
        const msal = msalRefBox.getValue();
        if (!msal) {
            return;
        }

        let authRes: AuthenticationResult;
        try {
            authRes = await msal.ssoSilent({
                account,
            });
        } catch (err) {
            console.error(err);
            return;
        }

        try {
            authRes ??= await msal.acquireTokenSilent({
                account,
                scopes: [],
            });
        } catch (err) {
            console.error(err);
            return;
        }

        try {
            authRes ??= await msal.loginPopup({
                account,
                scopes: [],
            });
        } catch (err) {
            console.error();
            return;
        }

        dispatch(setMsalAuthResultThunk(authRes));

        const login_hint = account?.idTokenClaims?.login_hint;
        if (!login_hint) {
            return;
        }

        localStorage.setItem(storeKeys.MSAL_LOGIN_HINT_KEY, login_hint);
    };

export const authenticateMsTeamsThunk = (
    loginHint?: string,
): ThunkAction<
    Promise<AuthenticationResult | null>,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(
        dispatch,
        getState,
    ): Promise<AuthenticationResult | null> {
        const translate = selectTranslate(getState());

        dispatch(
            startFetch({
                id: OperationId.AuthenticateMsTeams,
                message: translate('ms_login'),
            }),
        );

        const msAppId = selectMicrosoftAppId(getState());
        if (!msAppId) {
            dispatch(
                stopFetch({
                    operationId: OperationId.AuthenticateMsTeams,
                }),
            );
            return null;
        }

        const msal = new PublicClientApplication({
            auth: {
                clientId: msAppId,
                redirectUri: `${window.location.origin}/redirect.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                allowRedirectInIframe: true,
                loggerOptions: {
                    logLevel: LogLevel.Verbose,
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                console.warn(message);
                                return;
                            default:
                                console.log(message);
                                return;
                        }
                    },
                    piiLoggingEnabled: false,
                },
            },
        });
        await msal.initialize();
        msalRefBox.setValue(msal);

        let isCancelledByUser = false;
        const authRes = await authenticateMsTeams({
            isSilent:
                localStorage.getItem(storeKeys.MSAL_PROMPT_LOGIN) ===
                String(false),
            loginHint,
            msal,
            onUserCancelledPopup: () => {
                sessionStorage.setItem(
                    storeKeys.MSAL_PROMPT_LOGIN,
                    String(false),
                );
                isCancelledByUser = true;
            },
        });

        if (!authRes) {
            batch(() => {
                dispatch(
                    stopFetch({
                        operationId: OperationId.AuthenticateMsTeams,
                    }),
                );
                !isCancelledByUser && dispatch(setShowMsLoginDialog(true));
            });
            return null;
        }

        batch(() => {
            dispatch(setMsalAuthResultThunk(authRes));
            dispatch(
                stopFetch({
                    operationId: OperationId.AuthenticateMsTeams,
                }),
            );
        });
        return authRes;
    };

export const authenticateMsTeamsInteractivelyThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(dispatch, getState): Promise<void> {
        const teamsContext = selectTeamsContext(getState());
        const msal = msalRefBox.getValue();
        if (!msal) {
            return;
        }

        const authRes = await authenticateMsTeamsInteractively({
            loginHint: teamsContext?.user?.loginHint,
        });
        dispatch(setMsalAuthResultThunk(authRes));
    };

export const reauthenticateMsTeamsThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const teamsContext = selectTeamsContext(state);
        const translate = selectTranslate(state);

        const authRes = await authenticateMsTeams({
            isSilent: true,
            loginHint: teamsContext?.user?.loginHint,
        });

        if (!authRes) {
            dispatch(
                showNotification({
                    status: NotificationStatus.Error,
                    message: translate('ms_auth_fail'),
                    errorCode: AppErrorCode.AuthenticationFailed,
                }),
            );
        }

        dispatch(setMsalAuthResultThunk(authRes));
    };
