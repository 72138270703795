import { FieldErrors, FieldError, ErrorOption } from 'react-hook-form';
import { z } from 'zod';
import { Errors, ValidationError } from './model/validation';

export const getFirstFormValidationError = <T extends Record<string, unknown>>(
    errors: FieldErrors<T>,
): FieldError | null => {
    const firstEntry = Object.entries(errors)[0];
    if (!firstEntry) return null;

    return firstEntry[1] as FieldError;
};

export const getFirstValidationError = (
    errs: Errors,
): ValidationError | null => {
    const fieldErrors = Object.values(errs).find((errs) => errs[0]);
    return fieldErrors ? fieldErrors[0] : null;
};

export const validateFormField = async (
    fieldSchema: z.Schema,
    value: unknown,
    onValidationError?: (error: ErrorOption) => void,
): Promise<boolean> => {
    try {
        await fieldSchema.parseAsync(value);
    } catch (err) {
        if (err instanceof z.ZodError) {
            const firstIssue = err.issues[0];

            if (!firstIssue) {
                return false;
            }

            onValidationError &&
                onValidationError({
                    message: firstIssue.message,
                    type: firstIssue.code,
                });
        }

        return false;
    }

    return true;
};
