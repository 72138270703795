import { Stack, Text } from '@fluentui/react';
import React, { CSSProperties } from 'react';
import { appStyles } from '../../helpers';

export type PageHeadingProps = {
    text: string;
    containerStyle?: CSSProperties;
};

const pageHeadingContainerStyle: CSSProperties = {
    textAlign: 'left',
};

export const PageHeading = ({
    text,
    containerStyle,
}: PageHeadingProps): JSX.Element => {
    const mergedContainerStyle = containerStyle
        ? {
              ...pageHeadingContainerStyle,
              ...containerStyle,
          }
        : pageHeadingContainerStyle;

    return (
        <Stack
            className={appStyles.globalStyles.pageHeaderLayout}
            style={mergedContainerStyle}
        >
            <Text className={appStyles.globalStyles.pageHeader}>{text}</Text>
        </Stack>
    );
};
