import { RootState } from '..';

export const selectTeamsContext = (state: RootState) =>
    state.conversation.teamsContext;

export const selectChatMembers = (state: RootState) =>
    state.conversation.chatMembers;

export const selectIsExternalMember = (state: RootState): boolean | null =>
    state.conversation.isExternalMember;

export const selectShareContext = (state: RootState) =>
    state.conversation.shareContext;

export const selectQueryToUserIdsResponse = (state: RootState) =>
    state.conversation.queryToUserIdsResponse;
