import axios, { Axios, AxiosError } from 'axios';
import { store } from '../../store';
import { selectMsalAccessToken } from '../../store/selectors/msal';
import { reauthenticateMsTeamsThunk } from '../../store/actions/msal/thunks';
import { throttle } from '../../helpers';
import { IHttpAuthProvider, MsTeamsUserPresence } from './model';

enum ChatMemberRole {
    Owner = 'owner',
}

export type ChatMember = {
    '@odata.type': '#microsoft.graph.aadUserConversationMember';
    id: string;
    roles: ChatMemberRole[];
    displayName: string;
    visibleHistoryStartDateTime: string;
    userId: string;
    email: string;
    tenantId: string;
};

type GraphApiData<T extends Record<string, unknown>> = {
    '@odata.context': string;
} & T;

export type GraphApiListData<T> = {
    '@odata.context': string;
    '@odata.count': number;
    value: T[];
};

// Channel is in "preview" state if it has no messages
export const isPreviewChat = (chatId: string): boolean => {
    const [_, secondPart] = chatId.split(':');
    return secondPart.startsWith('preview');
};

export class MsGraphApi {
    private axios: Axios;

    constructor(private authProvider: IHttpAuthProvider) {
        this.axios = axios.create({
            baseURL: 'https://graph.microsoft.com/v1.0',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.axios.interceptors.response.use(undefined, async (err) => {
            if (
                err instanceof AxiosError &&
                (err.response.status === 401 || err.response.status === 403)
            ) {
                await this.authProvider.handleAuthError(
                    err.response.status,
                    err.response.data,
                    err.response.headers,
                );
            }

            return Promise.reject(err);
        });
    }

    async getOwnPresence(): Promise<GraphApiData<MsTeamsUserPresence> | null> {
        try {
            const accessToken = await this.authProvider.getToken();
            if (!accessToken) {
                throw new Error('No access token provided');
            }

            const res = await this.axios.get<GraphApiData<MsTeamsUserPresence>>(
                '/me/presence',
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    async getChatMembers(
        chatId: string,
    ): Promise<GraphApiListData<ChatMember> | null> {
        try {
            const accessToken = await this.authProvider.getToken();
            if (!accessToken) {
                throw new Error('No access token provided');
            }

            const res = await this.axios.get<GraphApiListData<ChatMember>>(
                `/me/chats/${chatId}/members`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}

export const msGraphApi = new MsGraphApi({
    getToken() {
        return selectMsalAccessToken(store.getState());
    },
    handleAuthError: throttle(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.dispatch(reauthenticateMsTeamsThunk());
    }, 240_000),
});
