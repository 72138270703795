/**
 * Component displays the button with predefined (onstants) values
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, Image, IImageProps } from '@fluentui/react';
import { appStyles, getImageByContetntType, Utilities } from '../../helpers';
import { FileType } from '../../model';

const File = (props) => {
    const getImageByType = (
        contenttype: string,
        type: FileType,
        name: string,
        shared?: boolean,
    ) => {
        const img = getImageByContetntType(contenttype, type, name, shared);
        const ControlImage: IImageProps = {
            src: img,
            height: '22px',
        };
        return ControlImage;
    };

    return (
        <Stack horizontal key={props.item.name} tokens={{ childrenGap: 5 }}>
            <Stack style={{ width: '25px' }} verticalAlign="center">
                <Image
                    {...getImageByType(
                        props.item.contenttype,
                        props.item.type,
                        props.item.name,
                        props.item.shared,
                    )}
                />
            </Stack>
            <Stack verticalAlign="center">
                <Text className={appStyles.globalStyles.file}>
                    {' '}
                    {props.displayPath === true
                        ? `${props.item.parentPath}/${props.item.name}`
                        : props.item.name}
                </Text>
                <Text className={appStyles.globalStyles.date}>
                    {' '}
                    {Utilities.parseDatetime(props.item.lastmod)}
                </Text>
                {!Utilities.isNANObject(props.item.content) &&
                !Utilities.isNANObject(props.destinationFolder) ? (
                    <Text
                        className={appStyles.globalStyles.date}
                        // eslint-disable-next-line max-len
                    >{`${props.destinationFolder.parentPath}/${props.destinationFolder.name}`}</Text>
                ) : (
                    <></>
                )}
            </Stack>
        </Stack>
    );
};

export default File;

File.propTypes = {
    item: PropTypes.any,
    displayPath: PropTypes.bool,
    destinationFolder: PropTypes.any,
};
