import { webdavApi } from 'nextcloud-api';
import { z } from 'zod';
import { store } from '../store';
import { selectStubUrl } from '../store/selectors/app';
import { selectUser, selectUserId } from '../store/selectors/auth';
import { selectTranslate } from '../store/selectors/ui';
import { CreateFolderData } from '../model';

export const uniqueFolderPathRefinement = async (
    { path, folderName }: CreateFolderData,
    context: z.RefinementCtx,
) => {
    const state = store.getState();
    const user = selectUser(state);
    const stubUrl = selectStubUrl(state);
    const userId = selectUserId(state);
    const translate = selectTranslate(state);
    if (!user || !userId || !stubUrl) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('something_went_wrong'),
        });
        return z.NEVER;
    }

    const res = await webdavApi.checkFolderExist(
        stubUrl,
        user,
        userId,
        `${path}/${folderName}`,
    );

    if (!res.success) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('something_went_wrong'),
        });
        return z.NEVER;
    }

    if (res.result) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('newfolder_exists'),
        });
        return z.NEVER;
    }

    return null;
};

export const createFolderFormDataSchema: z.ZodSchema<CreateFolderData> = z
    .object({
        path: z.string(),
        folderName: z.string(),
    })
    .superRefine(uniqueFolderPathRefinement) as z.ZodSchema<CreateFolderData>;
