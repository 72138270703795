import React, { CSSProperties, MouseEvent } from 'react';
import {
    Stack,
    FontIcon,
    Text,
    IStackStyles,
    ITextStyles,
} from '@fluentui/react';

const containerStyles: IStackStyles = {
    root: {
        cursor: 'pointer',
        all: 'unset',
        display: 'flex !important',
        alignItems: 'center',
        gap: 12,
    },
};

const fontIconStyle: CSSProperties = {
    color: 'white',
    backgroundColor: '#98908C',
    borderRadius: '50%',
    padding: 8,
    fontSize: 16,
    fontWeight: 500,
};

const headingStyles: ITextStyles = { root: { fontWeight: 600 } };

type Props = {
    onClick: (e: MouseEvent) => void;
};

export const InternalLinkSection = ({ onClick }: Props): JSX.Element => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
    };

    return (
        <Stack
            styles={containerStyles}
            as="button"
            itemType="button"
            onClick={handleClick}
        >
            <FontIcon iconName="Share" style={fontIconStyle} />
            <Stack>
                <Text variant="medium" styles={headingStyles}>
                    Internal Link
                </Text>
                <Text variant="small">
                    Only works for users with access to this folder
                </Text>
            </Stack>
        </Stack>
    );
};
