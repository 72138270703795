import React from 'react';
import { useWizard } from '../../wizard';
import { PageHeadingWithBackButton } from '../common/pageHeadingWithBackButton';

export const WizardPageHeading = (): JSX.Element => {
    const { prev } = useWizard();

    return (
        <PageHeadingWithBackButton
            text="Select share method"
            onBackButtonClick={prev}
        />
    );
};
