import { FileItem, ServerFile } from '../model';
import { store } from '../store';
import { selectFilesToUpload } from '../store/selectors/app';
import { countBeforeIndex } from './utils';
import * as path from 'path';

export const getRepeatedFileName = (
    fileName: string,
    fileNumber: number,
): string => {
    return (
        path.basename(fileName, path.extname(fileName)) +
        '_' +
        fileNumber +
        path.extname(fileName)
    );
};

export const mapPlainFilesToFileItems = (
    plainFiles: File[],
    fileContentArr: ArrayBuffer[],
): FileItem[] =>
    plainFiles.map((file, index) => {
        const extensionlessName = path.parse(file.name).name;
        const fileName = path.basename(file.name);

        const filesToUpload = selectFilesToUpload(store.getState());
        const uploadedNameOccurenceCount = countBeforeIndex(
            filesToUpload,
            filesToUpload.length,
            (value) => value.name.startsWith(extensionlessName),
        );

        const plainFileNameOccurenceCount = countBeforeIndex(
            plainFiles,
            index,
            (plainFile) => plainFile.name === fileName,
        );

        const sameNameFileCount =
            uploadedNameOccurenceCount + plainFileNameOccurenceCount;
        const isFileRepeated = sameNameFileCount > 0;
        const newName = isFileRepeated
            ? getRepeatedFileName(file.name, sameNameFileCount)
            : fileName;

        return {
            name: newName,
            type: 'file',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            lastmod: file?.lastModifiedDate ?? new Date(file.lastModified),
            contenttype: file.type,
            content: fileContentArr[index],
            selected: false,
        };
    });

export const reduceFileSize = (
    acc: number,
    file: ServerFile | FileItem,
): number => {
    if (typeof file.contentlength === 'number') {
        return acc + file.contentlength;
    }

    if ('content' in file) {
        return acc + file.content.byteLength;
    }

    return acc;
};
