import React, { CSSProperties, FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { Wizard, WizardStepMeta } from '../wizard';
// eslint-disable-next-line max-len
import { HorizontalChoiceGroup } from '../components/common/horizontalChoiceGroup';
import { ShareContext } from '../store/reducers/conversation/reducer';
import { withUserConfigGuard } from '../hoc/userConfigGuard';
// eslint-disable-next-line max-len
import {
    OrganizationSharePageProps,
    OrganizationSharePage,
    ShareTypePageVariant,
} from './organizationShare';
import { PublicSharePage, PublicSharePageProps } from './publicShare';
import { withTabs } from '../hoc/tabs';
import {
    Dialog,
    DialogType,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import { validateFormField } from '../helpers/validation';
import {
    selectDestinationFolder,
    selectFilesToUpload,
} from '../store/selectors/app';
import { SelectFileStep } from '../components/wizard/steps/selectFile';
import { WarningDialog } from '../components/dialogs';
import { useCustomValidationDialog } from '../helpers/hooks';
import { destinationFolderSchema, dndFilesSchema } from '../schemas/shared';
import { WizardPageHeading } from '../components/wizard/pageHeading';
import { ValidationError } from '../helpers/model/validation';
import { selectShareContext } from '../store/selectors/conversation';
import { withConversationContextGuard } from '../hoc/conversationContextGuard';
import { withLicenseLevelGuard } from '../hoc/licenseLevelGuard';
import { LicenseLevel } from 'nextcloud-api';
import { selectTranslate } from '../store/selectors/ui';
import { appStyles } from '../helpers';
// eslint-disable-next-line max-len
import { authenticateMsTeamsInteractivelyThunk } from '../store/actions/msal/thunks';
import { setShowMsLoginDialog } from '../store/actions/msal/actions';
import { msalRefBox } from '../services/statusSync/msalRefBox';

const MsLoginDialog = (): JSX.Element | null => {
    const showMsAuthDialog = useAppSelector(
        (state) => state.msal.showMsLoginDialog,
    );
    const dispatch = useAppDispatch();

    if (!showMsAuthDialog) {
        return null;
    }

    return (
        <Dialog
            styles={appStyles.DialogStyle}
            hidden={false}
            onDismiss={() => dispatch(setShowMsLoginDialog(false))}
            dialogContentProps={{ type: DialogType.close }}
        >
            <Stack
                tokens={{ childrenGap: 10 }}
                styles={{ root: { justifyContent: 'center' } }}
            >
                <Text>Please, log into your Microsoft account</Text>
                <PrimaryButton
                    text="Login"
                    onClick={() =>
                        dispatch(authenticateMsTeamsInteractivelyThunk())
                    }
                />
            </Stack>
        </Dialog>
    );
};

const shareContextToPageMap = {
    [ShareContext.Organization]: OrganizationSharePage,
    [ShareContext.Public]: PublicSharePage,
    [ShareContext.Any]: PublicSharePage,
} as const satisfies Record<
    ShareContext,
    FC<PublicSharePageProps | OrganizationSharePageProps>
>;

const horizontalChoiceGroupStyle: CSSProperties = { marginBottom: 12 };

type ShareTypeSelectorProps = {
    variant: ShareTypePageVariant;
};

export const ShareTypeSelector = ({
    variant,
}: ShareTypeSelectorProps): JSX.Element => {
    const shareContext = useAppSelector(selectShareContext);
    const translate = useAppSelector(selectTranslate);

    const defaultSelectedKey =
        !shareContext ||
        shareContext === ShareContext.Any ||
        shareContext === ShareContext.Public
            ? ShareContext.Public
            : ShareContext.Organization;

    const [contextToDisplay, setContextToDisplay] = useState<
        ShareContext.Public | ShareContext.Organization
    >(defaultSelectedKey);

    const Page = shareContextToPageMap[contextToDisplay];

    return (
        <>
            <WizardPageHeading />
            <HorizontalChoiceGroup
                style={horizontalChoiceGroupStyle}
                selectedKey={contextToDisplay}
                onChange={(ev, option) => setContextToDisplay(option.key)}
                options={[
                    {
                        key: ShareContext.Organization,
                        text: translate('organization'),
                    },
                    { key: ShareContext.Public, text: translate('public') },
                ]}
            />
            <Page variant={variant} />
        </>
    );
};

const SharePage = (): JSX.Element => {
    const files = useAppSelector(selectFilesToUpload);
    const destinationFolder = useAppSelector(selectDestinationFolder);

    const {
        firstError,
        setErrors,
        setShowValidationDialog,
        showValidationDialog,
    } = useCustomValidationDialog<'files'>();

    const validateFiles = async (): Promise<boolean> => {
        const fileErrors: ValidationError[] = [];

        const filesValid = await validateFormField(
            dndFilesSchema,
            files,
            (err) =>
                fileErrors.push({
                    type: 'file_length',
                    message: err.message ?? '',
                }),
        );

        const destinationFileValid = await validateFormField(
            destinationFolderSchema,
            destinationFolder,
            (err) =>
                fileErrors.push({
                    type: 'destination_folder',
                    message: err.message ?? '',
                }),
        );

        setErrors({
            files: fileErrors,
        });

        return filesValid && destinationFileValid;
    };

    const stepMetaArray: WizardStepMeta[] = [
        {
            tag: 'files',
            canMoveToNext: validateFiles,
        },
        { tag: 'form' },
    ];

    return (
        <Wizard.Provider stepMetaArr={stepMetaArray}>
            <Stack className="main-part">
                <MsLoginDialog />
                <button
                    onClick={() => {
                        try {
                            msalRefBox.getValue()?.logoutRedirect();
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                >
                    logoutRedirect
                </button>
                <button
                    onClick={() => {
                        try {
                            msalRefBox.getValue()?.logoutPopup();
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                >
                    logoutPopup
                </button>

                <Wizard.Steps>
                    <SelectFileStep />
                    <ShareTypeSelector variant={ShareTypePageVariant.Regular} />
                </Wizard.Steps>
            </Stack>
            <WarningDialog
                hidden={!showValidationDialog}
                subText={firstError?.message}
                icon="ErrorBadge"
                color="red"
                onDismiss={() => setShowValidationDialog(false)}
            />
        </Wizard.Provider>
    );
};

export default withTabs(
    withUserConfigGuard(
        withConversationContextGuard(
            withLicenseLevelGuard(SharePage, LicenseLevel.Standard),
        ),
    ),
);
