import { WithKey, HasAllRequiredKeysOf } from './utility-types';

type WithoutBuild<T extends { build: unknown }> = Omit<T, 'build'>;

export class Builder<
    T extends Record<string, unknown>,
    CurrentDraft extends Partial<T> = Partial<T>,
> {
    private draft: Partial<T> = {};

    private constructor() {}

    static of<Obj extends Record<string, unknown>>(): WithoutBuild<
        // eslint-disable-next-line @typescript-eslint/ban-types
        Builder<Obj, {}>
    > {
        // eslint-disable-next-line @typescript-eslint/ban-types
        return new Builder<Obj, {}>();
    }

    addOrReplace<
        Key extends keyof T,
        UpdatedDraft extends WithKey<CurrentDraft, Key, T[Key]>,
    >(
        key: Key,
        value: T[Key],
    ): HasAllRequiredKeysOf<UpdatedDraft, T> extends true
        ? Builder<T, UpdatedDraft>
        : WithoutBuild<Builder<T, UpdatedDraft>> {
        this.draft[key] = value;
        return this;
    }

    replaceNullish<
        Key extends keyof T,
        UpdatedDraft extends WithKey<CurrentDraft, Key, T[Key]>,
    >(
        key: Key,
        value: T[Key],
    ): HasAllRequiredKeysOf<UpdatedDraft, T> extends true
        ? Builder<T, UpdatedDraft>
        : WithoutBuild<Builder<T, UpdatedDraft>> {
        this.draft[key] ??= value;
        return this;
    }

    build(): T {
        return this.draft as T;
    }
}
