import { SharePermissions } from 'nextcloud-api';
import { Builder } from '../helpers/builder';
import { store } from '../store';
import { ShareContext } from '../store/reducers/conversation/reducer';
import { selectFilesToUpload } from '../store/selectors/app';
import {
    selectTeamsContext,
    selectChatMembers,
    selectShareContext,
} from '../store/selectors/conversation';

export type ShareByEmailData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    files: any[];
    email: string;
    permissions: SharePermissions;
};

export const getIntialEmailShareValues = (): ShareByEmailData => {
    const state = store.getState();
    const teamsContext = selectTeamsContext(state);
    const chatMemebers = selectChatMembers(state);

    const shareContext = selectShareContext(state);

    const builder = Builder.of<ShareByEmailData>();

    if (shareContext !== ShareContext.Any) {
        const otherMemberEmail =
            chatMemebers.find(
                (member) => member.userId !== teamsContext.user?.id,
            )?.email ?? '';
        builder.addOrReplace('email', otherMemberEmail);
    }

    return builder
        .addOrReplace('files', selectFilesToUpload(state))
        .addOrReplace('permissions', SharePermissions.Read)
        .replaceNullish('email', '')
        .build();
};
