import {
    IDropdownOption,
    Stack,
    PrimaryButton,
    Dropdown,
} from '@fluentui/react';
import React from 'react';
import { appStyles } from '../../helpers';
import { useAppSelector, useAppDispatch } from '../../store';
import {
    authenticateMsTeamsThunk,
    setMsalActiveAccountThunk,
} from '../../store/actions/msal/thunks';
import { selectMsalActiveAccount } from '../../store/selectors/msal';
import { selectTranslate } from '../../store/selectors/ui';
import { Account } from '../common/email';
import { AccountInfo } from '@azure/msal-browser';
import { msalRefBox } from '../../services/statusSync/msalRefBox';

const mapAccountsToDropdownOption = (
    account: AccountInfo,
): IDropdownOption<AccountInfo> => {
    return {
        key: account.username,
        text: account.name,
        data: account,
    };
};

export const MsAccountsDropdown = () => {
    const translate = useAppSelector(selectTranslate);
    const activeAccount = useAppSelector(selectMsalActiveAccount);
    const dispatch = useAppDispatch();

    const accounts = msalRefBox.getValue()?.getAllAccounts() ?? [];
    const options = accounts.map(mapAccountsToDropdownOption);

    if (!activeAccount || accounts.length === 0) {
        return (
            <Stack horizontalAlign="start">
                <PrimaryButton
                    styles={appStyles.primaryButton}
                    text={translate('login_sign_in')}
                    onClick={() => dispatch(authenticateMsTeamsThunk())}
                />
            </Stack>
        );
    }

    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<AccountInfo>,
        _index?: number,
    ): void => {
        if (!option || !option.data) {
            return;
        }
        dispatch(setMsalActiveAccountThunk(option.data));
    };

    const onRenderOption = (option: IDropdownOption) => {
        return <Account emailAdress={String(option.key)} />;
    };

    return (
        <Dropdown
            responsiveMode={2}
            options={options}
            selectedKey={activeAccount.username}
            onRenderOption={onRenderOption}
            onChange={onChange}
        />
    );
};
