import { passwordApi } from 'nextcloud-api';
import { Builder } from '../helpers/builder';
import { store } from '../store';
import { selectFilesToUpload, selectStubUrl } from '../store/selectors/app';
import { selectFileSharingSettings, selectUser } from '../store/selectors/auth';
import {
    PublicShareCacheData,
    publicShareCacheSchema,
    PublicShareData,
} from '../schemas/shareFile';
import { RefBox } from '../helpers/refBox';

export const publicShareCacheRefBox = new RefBox<PublicShareCacheData>(null);

export const getIntialPublicShareValues =
    async (): Promise<PublicShareData | null> => {
        const state = store.getState();
        const stubUrl = selectStubUrl(state);
        const user = selectUser(state);
        const filesSharingSettings = selectFileSharingSettings(state);

        if (!stubUrl || !filesSharingSettings) {
            return null;
        }

        const builder = Builder.of<PublicShareData>();

        const parsed = publicShareCacheSchema.safeParse(
            publicShareCacheRefBox.getValue(),
        );
        if (parsed.success === true) {
            return builder
                .replaceNullish('password', parsed.data.password)
                .replaceNullish('expirationDate', parsed.data.expirationDate)
                .replaceNullish(
                    'shareWithoutPassword',
                    parsed.data.shareWithoutPassword,
                )
                .replaceNullish('files', selectFilesToUpload(state))
                .build();
        }

        console.error('Error parsing public share cache', parsed.error);

        if (filesSharingSettings.PasswordEnabled) {
            const passwordRes = await passwordApi.generatePassword(
                stubUrl,
                user,
            );
            if (passwordRes.success) {
                builder
                    .addOrReplace(
                        'password',
                        passwordRes.result.ocs.data.password,
                    )
                    .addOrReplace('shareWithoutPassword', false);
            }
        }

        if (filesSharingSettings.ExpireDateEnabled) {
            const expirationDate = new Date();

            const expireDays =
                filesSharingSettings.ExpireDateDays > 0
                    ? filesSharingSettings.ExpireDateDays
                    : 1;

            expirationDate.setDate(expirationDate.getDate() + expireDays);
            builder.addOrReplace('expirationDate', expirationDate);
        }

        return builder
            .addOrReplace('files', selectFilesToUpload(state))
            .build();
    };
