/**
 * Authentication actions types
 */

export const authConstants = {
    LOGIN_SUCCESS: 'users.LOGIN_SUCCESS',
    LOGOUT: 'users.LOGOUT',
    LOGIN_PROCESS: 'users.LOGIN_PROCESS',
    LOGIN_FAILED: 'users.LOGIN_FAILED',
    APP_CHANGE_USER_CONFIG: 'users.APP_CHANGE_USER_CONFIG',
    APP_CHANGE_USER_SETTINGS: 'users.APP_CHANGE_USER_SETTINGS',
    APP_APPLY_USER_SETTINGS: 'users.APP_APPLY_USER_SETTINGS',
    PASSWORD_DIALOG_SKIPPED: 'users.PASSWORD_DIALOG_SKIPPED',
} as const;
