import { AuthenticationResult } from '@azure/msal-browser';
import { toAuthenticationResult } from '../store/reducers/msal/reducer';

export const exchangeTeamsToken = async (
    teamsToken: string,
): Promise<AuthenticationResult | null> => {
    try {
        const res = await fetch('/api/exchange-token', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${teamsToken}`,
            },
        });

        const body = await res.json();
        return toAuthenticationResult(body.data);
    } catch (err) {
        console.error(err);
    }

    return null;
};
