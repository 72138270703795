import { ThunkAction } from 'redux-thunk';
import { RootState } from '../..';
import { AnyAction } from 'redux';
import * as microsoftTeams from '@microsoft/teams-js';
import { authenticateMsTeamsThunk } from '../msal/thunks';
import {
    ChatMember,
    isPreviewChat,
    msGraphApi,
} from '../../../services/statusSync/msGraphApi';
import {
    setChatMembers,
    setIsExternalMember,
    setShareContext,
    setTeamsContext,
} from './actionCreators';
import { OperationId, startFetch, stopFetch } from '../app/actionCreators';
import { batch } from 'react-redux';
import { selectStubUrl } from '../../selectors/app';
import { selectUser } from '../../selectors/auth';
import { ShareContext } from '../../reducers/conversation/reducer';
import { searchUserIdsThunk } from '../app/thunks';
import { AppErrorCode } from '../app/actionTypes';
import { selectTranslate } from '../../selectors/ui';

export const getConversationContextThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> =>
    async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !stubUrl) {
            dispatch(setShareContext(ShareContext.Any));
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.LoadConversationContext,
                message: 'Loading conversation context...',
            }),
        );

        const teamsContext = await microsoftTeams.app.getContext();
        const msAuthRes = await dispatch(
            authenticateMsTeamsThunk(teamsContext?.user?.loginHint),
        );

        dispatch(setTeamsContext(teamsContext));

        const isNotLoggedIn = !msAuthRes;
        if (isNotLoggedIn) {
            batch(() => {
                dispatch(setShareContext(ShareContext.Any));
                dispatch(
                    stopFetch({
                        operationId: OperationId.LoadConversationContext,
                    }),
                );
            });
            return;
        }

        let chatMembers: ChatMember[] = [];
        if (teamsContext.chat) {
            const chatMembersRes = await msGraphApi.getChatMembers(
                teamsContext.chat.id,
            );
            chatMembers = chatMembersRes?.value ?? [];
            if (chatMembersRes) {
                dispatch(setChatMembers({ chatMembers }));
            }
        }

        const isChannel = Boolean(teamsContext.channel);
        const isGroupChat = chatMembers.length > 2;
        const isChatInPreview =
            Boolean(teamsContext?.chat) && isPreviewChat(teamsContext.chat.id);
        const isUnknownCase = !teamsContext.chat;

        if (isChannel || isGroupChat || isChatInPreview || isUnknownCase) {
            batch(() => {
                dispatch(setShareContext(ShareContext.Any));
                dispatch(
                    stopFetch({
                        operationId: OperationId.LoadConversationContext,
                    }),
                );
            });
            return;
        }

        const chatMember = chatMembers.find(
            (member) => member.userId !== teamsContext?.user?.id,
        );
        const userIdsRes = await dispatch(
            searchUserIdsThunk(chatMember?.email),
        );

        if (!userIdsRes.success) {
            batch(() => {
                setShareContext(ShareContext.Any);
                stopFetch({
                    operationId: OperationId.LoadConversationContext,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                });
            });
            return;
        }

        const nextcloudAccountExists =
            userIdsRes.result.ocs.data.users.length > 0;

        const shareContext = nextcloudAccountExists
            ? ShareContext.Organization
            : ShareContext.Public;

        batch(() => {
            dispatch(setShareContext(shareContext));
            dispatch(setIsExternalMember(!nextcloudAccountExists));
            dispatch(
                stopFetch({
                    operationId: OperationId.LoadConversationContext,
                }),
            );
        });
    };
