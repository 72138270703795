import { nextcloudApi, SharePermissions } from 'nextcloud-api';
import { z } from 'zod';
import { store } from '../store';
import { selectStubUrl } from '../store/selectors/app';
import { selectUser } from '../store/selectors/auth';
import { selectTranslate } from '../store/selectors/ui';
import { searchUserIdsThunk } from '../store/actions/app/thunks';

export const existingNextcloudUserEmailRefinement = async (
    email: string,
    context: z.RefinementCtx,
): Promise<void> => {
    const state = store.getState();
    const stubUrl = selectStubUrl(state);
    const user = selectUser(state);

    if (!stubUrl || !user) {
        return z.NEVER;
    }

    const translate = selectTranslate(state);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const accountsRes: Awaited<ReturnType<typeof nextcloudApi.searchUserIds>> =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await store.dispatch(searchUserIdsThunk(email));

    const accountExists =
        accountsRes.success && accountsRes.result.ocs.data.users.length > 0;
    if (accountExists) {
        return z.NEVER;
    }

    context.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate('user_doesnt_exist'),
    });
};

export const shareByEmailScehma = z.object({
    files: z.array(z.any()).min(1),
    email: z.string().email().superRefine(existingNextcloudUserEmailRefinement),
    permissions: z.union([
        z.literal(SharePermissions.Read),
        z.literal(SharePermissions.Update),
    ]),
});
