import React, { ChangeEvent } from 'react';
import { mergeStyleSets, Text } from '@fluentui/react';

const classNames = mergeStyleSets({
    label: {
        textWrap: 'nowrap',
        textAlign: 'center',
        display: 'inline-block',
        border: '1px solid #D8D8D8',
        borderRadius: 2,
        padding: '2px 11px 2px 11px',
        lineHeight: 20,

        '&:hover': {
            border: '1px solid rgb(50, 49, 48)',
        },
        '&.checked': {
            border: '1px solid #f36928',
        },
        '&.disabled': {
            border: '1px solid #D8D8D8',
            color: '#D8D8D8',
            cursor: 'not-allowed',
        },
    },
    input: {
        all: 'unset',
    },
});

type Props = {
    name: string;
    checked?: boolean;
    text: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const TextRadio = ({
    name,
    checked,
    text,
    onChange,
    disabled,
}: Props): JSX.Element => {
    const labelClassName = `${classNames.label} ${checked ? 'checked' : ''} ${
        disabled ? 'disabled' : ''
    }`;

    return (
        <label className={labelClassName}>
            <input
                type="radio"
                name={name}
                className={classNames.input}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <Text variant="small" color="#242424">
                {text}
            </Text>
        </label>
    );
};
