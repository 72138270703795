import {
    AuthenticationResult,
    BrowserAuthError,
    PublicClientApplication,
} from '@azure/msal-browser';
import { msalRefBox } from './msalRefBox';

const authenticateMsTeamsSilently = async ({
    loginHint,
    msal = msalRefBox.getValue(),
}: {
    loginHint?: string;
    msal?: PublicClientApplication;
}): Promise<AuthenticationResult | null> => {
    if (!msal) {
        return null;
    }

    const accounts = msal.getAllAccounts();

    try {
        if (accounts.length === 0) {
            const ssoRes = await msal.ssoSilent({
                loginHint,
            });

            return ssoRes;
        } else if (accounts.length === 1) {
            const silentRes = await msal.acquireTokenSilent({
                account: accounts[0],
                scopes: [],
            });

            return silentRes;
        }
    } catch (err) {
        console.error(err);
    }

    return null;
};

export const authenticateMsTeamsInteractively = async ({
    loginHint,
    msal = msalRefBox.getValue(),
    onUserCancelledPopup,
}: {
    loginHint?: string;
    msal?: PublicClientApplication;
    onUserCancelledPopup?: () => void;
}): Promise<AuthenticationResult | null> => {
    if (!msal) {
        return null;
    }

    try {
        const authRes = await msal.loginPopup({
            loginHint,
            scopes: [],
        });
        return authRes;
    } catch (err) {
        console.error(err);
        if (
            err instanceof BrowserAuthError &&
            err.errorCode === 'user_cancelled'
        ) {
            onUserCancelledPopup?.();
            return null;
        }
    }

    try {
        const authRes = await msal.handleRedirectPromise();
        console.log('redirect auth res', authRes);

        if (msal.getAllAccounts().length === 0 || !authRes) {
            console.log('Redirecting');
            await msal.loginRedirect({
                loginHint,
                scopes: [],
            });
        }

        return authRes;
    } catch (err) {
        console.error(err);
    }

    return null;
};

export type AuthenticateMsTeamsArgs = {
    loginHint?: string;
    isSilent: boolean;
    onUserCancelledPopup?: () => void;
    msal?: PublicClientApplication;
};

export const authenticateMsTeams = async ({
    loginHint,
    isSilent,
    // onUserCancelledPopup,
    msal = msalRefBox.getValue(),
}: AuthenticateMsTeamsArgs): Promise<AuthenticationResult | null> => {
    if (!msal) {
        return null;
    }

    const authRes = await authenticateMsTeamsSilently({
        loginHint,
        msal,
    });

    if (authRes) {
        return authRes;
    }

    if (isSilent) {
        return null;
    }

    return null;

    // const interactiveAuthRes = await authenticateMsTeamsInteractively({
    //     loginHint,
    //     msal,
    //     onUserCancelledPopup,
    // });

    // return interactiveAuthRes;
};
