import React from 'react';
import { withConversationContextGuard } from '../hoc/conversationContextGuard';
import { withUserConfigGuard } from '../hoc/userConfigGuard';
import { withLicenseLevelGuard } from '../hoc/licenseLevelGuard';
import { LicenseLevel } from 'nextcloud-api';
import { DndFilesStep } from '../components/wizard/steps/dndFiles';
import { Stack } from '@fluentui/react';
import { WarningDialog } from '../components/dialogs';
import { Wizard, WizardStepMeta } from '../wizard';
import { useCustomValidationDialog } from '../helpers/hooks';
import { validateFormField } from '../helpers/validation';
import { dndFilesSchema } from '../schemas/shared';
import { useAppSelector } from '../store';
import { selectFilesToUpload } from '../store/selectors/app';
import { withTabs } from '../hoc/tabs';
import { ShareTypeSelector } from './share';
import { ShareTypePageVariant } from './organizationShare';

const DndSharePage = (): JSX.Element => {
    const files = useAppSelector(selectFilesToUpload);

    const {
        firstError,
        setErrors,
        setShowValidationDialog,
        showValidationDialog,
    } = useCustomValidationDialog<'files'>();

    const validateFiles = async (): Promise<boolean> => {
        return validateFormField(dndFilesSchema, files, (err) =>
            setErrors({
                files: [
                    {
                        type: 'file_length',
                        message: err.message ?? '',
                    },
                ],
            }),
        );
    };

    const stepMetaArray: WizardStepMeta[] = [
        {
            tag: 'files',
            canMoveToNext: validateFiles,
        },
        { tag: 'share-type' },
    ];

    return (
        <Wizard.Provider stepMetaArr={stepMetaArray}>
            <Stack className="main-part">
                <Wizard.Steps>
                    <DndFilesStep />
                    <ShareTypeSelector variant={ShareTypePageVariant.Dnd} />
                </Wizard.Steps>
            </Stack>
            <WarningDialog
                hidden={!showValidationDialog}
                subText={firstError?.message}
                icon="ErrorBadge"
                color="red"
                onDismiss={() => setShowValidationDialog(false)}
            />
        </Wizard.Provider>
    );
};

export default withTabs(
    withUserConfigGuard(
        withLicenseLevelGuard(
            withConversationContextGuard(DndSharePage),
            LicenseLevel.Standard,
        ),
    ),
);
