import { BaseButton, Stack, PrimaryButton } from '@fluentui/react';
import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { appStyles } from '../../helpers';
import { useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';
import { useWizard } from '../../wizard';

type Props = {
    hidePrimaryButton?: boolean;
    primaryButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
    onPrimaryButtonClick?: MouseEventHandler<HTMLButtonElement | BaseButton>;
};

export const WizardFooter = ({
    hidePrimaryButton = false,
    primaryButtonType,
    onPrimaryButtonClick,
}: Props) => {
    const { isLastStep, next } = useWizard();

    const translate = useAppSelector(selectTranslate);

    const shouldRenderPrimaryButton = isLastStep && !hidePrimaryButton;

    return (
        <Stack className={appStyles.globalStyles.wizardButtons}>
            <Stack className={appStyles.globalStyles.sectionLayout20}>
                {!isLastStep && (
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        text={translate('button_next')}
                        onClick={next}
                    />
                )}

                {shouldRenderPrimaryButton && (
                    <PrimaryButton
                        type={primaryButtonType}
                        styles={appStyles.primaryButton}
                        text={translate('sharefiles_upload')}
                        onClick={onPrimaryButtonClick}
                    />
                )}
            </Stack>
        </Stack>
    );
};
