import React, { FormEvent, useMemo } from 'react';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    IChoiceGroupOptionStyleProps,
    IChoiceGroupOptionStyles,
    IChoiceGroupProps,
    IChoiceGroupStyles,
} from '@fluentui/react';

const choiceGroupStyles: IChoiceGroupStyles = {
    flexContainer: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
    },
};

const choiceOptionStylesFunc = (
    props: IChoiceGroupOptionStyleProps,
): IChoiceGroupOptionStyles => {
    const cursor = `${props.checked ? 'default' : 'pointer'} !important`;
    const borderColor = props.checked ? '#f36928' : '#F8F8F8';

    return {
        root: {
            flex: '1 1 0',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            padding: '0 5px 12px 5px',
            cursor,
            borderBottom: `2px solid ${borderColor}`,
            fontWeight: 600,
        },
        labelWrapper: {
            cursor,
        },
        input: {
            cursor,
        },
        field: {
            cursor,

            '&::before': {
                top: 'calc(50% - 10px) !important',
                cursor,
            },
            '&.is-checked::before': {
                borderColor: '#f36928',
            },
            '&::after': {
                cursor,
            },
            '&.is-checked::after': {
                top: 'calc(50% - 5px) !important',
            },
            '&:not(.is-checked)::after': {
                top: 'calc(50% - 10px) !important',
            },
            '&:not(.is-checked):hover::after': {
                top: 'calc(50% - 5px) !important',
            },
        },
    };
};

const toChoiceGroupOption = <TKey extends string>(
    horizontalChoiceGroupOption: HorizontalChoiceGroupOption<TKey>,
): Omit<IChoiceGroupOption, 'key'> & { key: TKey } => ({
    ...horizontalChoiceGroupOption,
    styles: choiceOptionStylesFunc,
});

type HorizontalChoiceGroupOption<TKey extends string> = Omit<
    IChoiceGroupOption,
    'key' | 'styles'
> & {
    key: TKey;
};

type Props<TKey extends string> = {
    selectedKey?: NoInfer<TKey>;
    defaultSelectedKey?: NoInfer<TKey>;
    options: HorizontalChoiceGroupOption<TKey>[];
    onChange: (
        ev?: FormEvent<HTMLElement | HTMLInputElement>,
        option?: Omit<IChoiceGroupOption, 'key' | 'styles'> & {
            key: TKey;
            styles: typeof choiceOptionStylesFunc;
        },
    ) => void;
} & Omit<IChoiceGroupProps, 'onChange' | 'styles'>;

export const HorizontalChoiceGroup = <T extends string>({
    selectedKey,
    defaultSelectedKey,
    options,
    onChange,
    ...rest
}: Props<T>): JSX.Element => {
    const choiceGroupOptions = useMemo(
        () => options.map(toChoiceGroupOption),
        [options],
    );

    return (
        <ChoiceGroup
            selectedKey={selectedKey}
            defaultSelectedKey={defaultSelectedKey}
            onChange={onChange}
            styles={choiceGroupStyles}
            options={choiceGroupOptions}
            {...rest}
        />
    );
};
