import React from 'react';
import { withUserConfigGuard } from '../hoc/userConfigGuard';
import { withTabs } from '../hoc/tabs';
import { FileExplorer } from '../components/fileExplorer/fileExplorer';
// eslint-disable-next-line max-len
import { CurrentFolderUploadMenu } from '../components/fileExplorer/components/uploadMenu';
import './nextcloudExplorer.css';
import { withLicenseLevelGuard } from '../hoc/licenseLevelGuard';
import { LicenseLevel } from 'nextcloud-api';

const NextcloudExplorerPage = () => {
    return (
        <div className="nextcloud-explorer-page">
            <CurrentFolderUploadMenu />
            <FileExplorer />
        </div>
    );
};

export default withTabs(
    withUserConfigGuard(
        withLicenseLevelGuard(NextcloudExplorerPage, LicenseLevel.Standard),
    ),
);
