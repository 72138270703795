import { AppErrorCode as LibraryAppErrorCode } from 'nextcloud-api';
import { ReduxAction, createReduxAction } from '../../utils';
import { AppErrorCode, NotificationStatus, appConstants } from './actionTypes';
import { LongOperation } from '../../reducers/app/reducer';

export enum OperationId {
    Logout = 'logout',
    SignIn = 'signin',
    AuthenticateMsTeams = 'authenticate_ms_teams',
    LoadUserConfiguration = 'load_user_configuration',
    UploadFile = 'upload_file',
    RenameFile = 'rename_file',
    DeleteFile = 'delete_file',
    CreateFolder = 'create_folder',
    LoadConversationContext = 'load_conversation_context',
    EmailShare = 'email_share',
    ServerFileShare = 'server_file_share',
    LoadDndFiles = 'load_dnd_files',
}

export type StartFetchActionPayload = LongOperation;

export type StartFetchAction = ReduxAction<
    typeof appConstants.APP_START_FETCH,
    StartFetchActionPayload
>;

export const startFetch = (
    payload: StartFetchActionPayload,
): StartFetchAction => createReduxAction(appConstants.APP_START_FETCH, payload);

export type StopFetchActionPayload = {
    operationId: OperationId;
    status?: NotificationStatus;
    errorCode?: AppErrorCode | LibraryAppErrorCode;
    message?: string;
};

export type StopFetchAction = ReduxAction<
    typeof appConstants.APP_STOP_FETCH,
    StopFetchActionPayload
>;

export const stopFetch = (payload: StopFetchActionPayload): StopFetchAction =>
    createReduxAction(appConstants.APP_STOP_FETCH, payload);

type ShowNotificationPayload = {
    status: NotificationStatus;
    errorCode?: AppErrorCode | LibraryAppErrorCode;
    message: string;
};

export type ShowNotificationAction = ReduxAction<
    typeof appConstants.APP_SHOW_NOTIFICATION,
    ShowNotificationPayload
>;

export const showNotification = (
    payload: ShowNotificationPayload,
): ShowNotificationAction =>
    createReduxAction(appConstants.APP_SHOW_NOTIFICATION, payload);

export const setFilesToUpload = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filesToUpload: any[],
): ReduxAction<
    typeof appConstants.APP_FILES_TO_UPLOAD,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { filesToUpload: any[] }
> => createReduxAction(appConstants.APP_FILES_TO_UPLOAD, { filesToUpload });

export const clearNotification = (): ReduxAction<
    typeof appConstants.APP_CLEAR_NOTIFICATION,
    undefined
> => createReduxAction(appConstants.APP_CLEAR_NOTIFICATION);

export const setProgressSize = (
    progressSize: number,
): ReduxAction<
    typeof appConstants.APP_PROGRESS_SIZE,
    { progressSize: number }
> => createReduxAction(appConstants.APP_PROGRESS_SIZE, { progressSize });

export const addUploadedSize = (
    uploadedSize: number,
): ReduxAction<typeof appConstants.APP_UPLOADED_SIZE, { uploadedSize }> =>
    createReduxAction(appConstants.APP_UPLOADED_SIZE, { uploadedSize });
