import * as microsoftTeams from '@microsoft/teams-js';

microsoftTeams.app.initialize();

export type ShareFileDialogResultData = {
    url: string;
    password: string;
    expirationdate: string;
};

export class ShareFileDialogResult {
    public type = 'shareFile';

    constructor(public data: ShareFileDialogResultData) {}
}

export type ShareLinkDialogResultData = {
    url: string;
    name: string;
};

export class ShareLinkDialogResult {
    public type = 'shareLink';

    constructor(public data: ShareLinkDialogResultData) {}
}

export type EmailShareDialogResultData = {
    url: string;
    name: string;
};

export class EmailShareDialogResult {
    public type = 'shareEmail';

    constructor(public data: EmailShareDialogResultData) {}
}

export type SharedFileInfo = {
    name: string;
    url: string;
};

export type DndPublicShareData = {
    sharedFileInfoArr: SharedFileInfo[];
    password?: string;
    expirationDate: string;
};

export class DndPublicShareDialogResult {
    public type = 'dndPublicShare';

    constructor(public data: DndPublicShareData) {}
}

export type DndEmailShareData = {
    sharedFileInfoArr: SharedFileInfo[];
};

export class DndEmailShareDialogResult {
    public type = 'dndEmailShare';

    constructor(public data: DndEmailShareData) {}
}

export const submitDialogResult = (
    dialogResult:
        | ShareFileDialogResult
        | ShareLinkDialogResult
        | EmailShareDialogResult
        | DndPublicShareDialogResult
        | DndEmailShareDialogResult,
): void => {
    microsoftTeams.dialog.url.submit(dialogResult);
};
