import { isResponseError, Utilities } from './../../../helpers/utils';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../..';
import { selectStubUrl } from '../../selectors/app';
import { selectUser, selectUserId } from '../../selectors/auth';
import {
    OperationId,
    setFilesToUpload,
    showNotification,
    startFetch,
    stopFetch,
} from './actionCreators';
import { AppErrorCode, NotificationStatus } from './actionTypes';
import {
    ItemType,
    OCSOkResponseBody,
    ResponseError,
    Share,
    SharePermissions,
    ShareType,
    nextcloudApi,
    webdavApi,
} from 'nextcloud-api';
import { appActions, createShareFolders } from './actions';
import * as path from 'path';
import {
    CreateFolderData,
    FileItem,
    RenameFileData,
    ServerFile,
} from '../../../model';
import {
    getRenamedFilePath,
    getStartingPathParts,
} from '../../../helpers/fileExplorer';
import { selectTranslate } from '../../selectors/ui';
import { batch } from 'react-redux';
import {
    DndEmailShareDialogResult,
    DndPublicShareDialogResult,
    EmailShareDialogResult,
    SharedFileInfo,
    ShareFileDialogResult,
    submitDialogResult,
} from '../../../services/dialogResults';
import { setUserIdsResponse } from '../conversation/actionCreators';
import { AppErrorCode as NextcloudAppErrorCode } from 'nextcloud-api';
import {
    selectQueryToUserIdsResponse,
    selectTeamsContext,
} from '../../selectors/conversation';
import { ErrOption } from '../../../helpers/model/errOption';
import * as microsoftTeams from '@microsoft/teams-js';
import { mapPlainFilesToFileItems } from '../../../helpers/file';
import { uploadFilesInParallel } from '../../../services/file';

export const copyFileShareLinkToClipboardThunk = (
    fileId: number,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        const link = `${user.server}/index.php/f/${fileId}`;

        try {
            await navigator.permissions.query({
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name: 'clipboard-write',
            });
        } catch (err) {
            console.error(err);
            if (!(err instanceof TypeError)) {
                dispatch(
                    showNotification({
                        errorCode: AppErrorCode.CustomError,
                        message: translate('allow_clipboard_access'),
                        status: NotificationStatus.Error,
                    }),
                );
                return;
            }
        }

        try {
            await navigator.clipboard.writeText(link);
        } catch (err) {
            console.error(err);
            dispatch(
                showNotification({
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        dispatch(
            showNotification({
                message: translate('link_copied_to_clipboard'),
                status: NotificationStatus.Success,
            }),
        );
    };
};

export const renameFileThunk = ({
    initialPath,
    newFilename,
}: RenameFileData): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.RenameFile,
                message: translate('renaming_item'),
            }),
        );

        const newPath = getRenamedFilePath(initialPath, newFilename);
        const fileDir = path.parse(newPath).dir;
        const res = await webdavApi.moveFile(
            stubUrl,
            user,
            userId,
            initialPath,
            newPath,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(fileDir));
            dispatch(
                stopFetch({
                    operationId: OperationId.RenameFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(fileDir));

        dispatch(
            stopFetch({
                operationId: OperationId.RenameFile,
            }),
        );
    };
};

export const deleteFileByPathThunk = (
    filePath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.DeleteFile,
                message: translate('deleting_item'),
            }),
        );

        const fileDir = path.parse(filePath).dir;

        const res = await webdavApi.deleteFileByPath(
            stubUrl,
            user,
            userId,
            filePath,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(fileDir));
            dispatch(
                stopFetch({
                    operationId: OperationId.DeleteFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(fileDir));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.DeleteFile,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('item_deleted'),
                    status: NotificationStatus.Success,
                }),
            );
        });
    };
};

export const openDownloadLinkThunk = (
    filePath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        if (!user || !userId) {
            return;
        }

        const url = `${user.server}/remote.php/dav/files/${userId}/${filePath}`;
        window.open(url, '_blank');
    };
};

export const openFileInNextcloudThunk = (
    fileId: number,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return function thunk(dispatch, getState): void {
        const user = selectUser(getState());
        if (!user) {
            return;
        }

        const url = `${user.server}/index.php/f/${fileId}`;
        window.open(url, '_blank');
    };
};

export const getServerFilesWithLoader = (
    parentFolderPath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, _getState): Promise<void> {
        dispatch(
            startFetch({
                id: OperationId.LoadUserConfiguration,
                message: 'load_serverfiles',
            }),
        );

        await dispatch(appActions.getServerFiles(parentFolderPath));

        dispatch(
            stopFetch({
                operationId: OperationId.LoadUserConfiguration,
            }),
        );
    };
};

export const getInitialServerFilesThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return function thunk(dispatch, _getState) {
        const startingPathParts = getStartingPathParts();
        if (!startingPathParts) {
            dispatch(getServerFilesWithLoader(''));
            return;
        }
        dispatch(getServerFilesWithLoader(startingPathParts.at(-1).path));
    };
};

type UploadFileData = {
    folder: string;
    fileBase: string;
    content: string | ArrayBufferLike;
};

export enum UploadFileThunkError {
    InvalidState = 'invalid_state',
    FileExistsCheckFailed = 'file_exists_check_failed',
    FileExists = 'file_exists',
    FileUploadFailed = 'file_upload_failed',
}

export const uploadFileThunk = ({
    folder,
    fileBase,
    content,
}: UploadFileData): ThunkAction<
    Promise<
        | Exclude<ReturnType<typeof webdavApi.copyOrCreateFile>, ResponseError>
        | ErrOption<UploadFileThunkError>
    >,
    RootState,
    unknown,
    AnyAction
> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return new ErrOption(
                'Invalid state',
                UploadFileThunkError.InvalidState,
            );
        }

        dispatch(
            startFetch({
                id: OperationId.UploadFile,
                message: translate('upload_selected'),
            }),
        );

        const uploadPath = `${folder}/${fileBase}`;
        const fileExistsRes = await webdavApi.exists(
            stubUrl,
            user,
            userId,
            uploadPath,
        );
        if (!fileExistsRes.success) {
            await dispatch(appActions.getServerFiles(folder));
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return new ErrOption(
                fileExistsRes.result.message,
                UploadFileThunkError.FileExistsCheckFailed,
            );
        }
        if (fileExistsRes.result) {
            await dispatch(appActions.getServerFiles(folder));
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('file_already_exists'),
                    status: NotificationStatus.Error,
                }),
            );
            return new ErrOption(
                `File ${uploadPath} already exists`,
                UploadFileThunkError.FileExists,
            );
        }

        const uploadRes = await webdavApi.copyOrCreateFile(
            stubUrl,
            user,
            userId,
            folder,
            uploadPath,
            content,
            () => {},
        );
        if (!uploadRes.success) {
            await dispatch(appActions.getServerFiles(folder));
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return new ErrOption(
                uploadRes.result.message,
                UploadFileThunkError.FileUploadFailed,
            );
        }

        await dispatch(appActions.getServerFiles(folder));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('upload_finished_header'),
                    status: NotificationStatus.Success,
                }),
            );
        });

        return uploadRes.result;
    };
};

export enum UploadShareFileThunkError {
    InvalidState = 'invalid_state',
    FolderCreationFailed = 'folder_creation_failed',
    FileExistsCheckFailed = 'file_exists_check_failed',
    FileExists = 'file_exists',
    FileUploadFailed = 'file_upload_failed',
    UploadedFileNotFound = 'uploaded_file_not_found',
}

type UploadShareFileData = UploadFileData & { allowOverwrite: boolean };

export const uploadShareFileThunk = ({
    folder,
    fileBase,
    content,
    allowOverwrite,
}: UploadShareFileData): ThunkAction<
    Promise<ServerFile | ErrOption<UploadShareFileThunkError>>,
    RootState,
    unknown,
    AnyAction
> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return new ErrOption(
                'Invalid state',
                UploadShareFileThunkError.InvalidState,
            );
        }

        dispatch(
            startFetch({
                id: OperationId.UploadFile,
                message: translate('upload_selected'),
            }),
        );

        const uploadPath = `${folder}/${fileBase}`;

        const isFolderCreated = await createShareFolders(folder);

        if (!isFolderCreated) {
            return new ErrOption(
                `Could not create folder for ${uploadPath}`,
                UploadShareFileThunkError.FolderCreationFailed,
            );
        }

        if (!allowOverwrite) {
            const fileExistsRes = await webdavApi.exists(
                stubUrl,
                user,
                userId,
                uploadPath,
            );
            if (!fileExistsRes.success) {
                dispatch(
                    stopFetch({
                        operationId: OperationId.UploadFile,
                        errorCode: AppErrorCode.CustomError,
                        message: translate('upload_selected_error'),
                        status: NotificationStatus.Error,
                    }),
                );
                return new ErrOption(
                    fileExistsRes.result.message,
                    UploadShareFileThunkError.FileExistsCheckFailed,
                );
            }
            if (fileExistsRes.result) {
                dispatch(stopFetch({ operationId: OperationId.UploadFile }));
                return new ErrOption(
                    `File ${uploadPath} already exists`,
                    UploadShareFileThunkError.FileExists,
                );
            }
        }

        const uploadRes = await webdavApi.copyOrCreateFile(
            stubUrl,
            user,
            userId,
            folder,
            uploadPath,
            content,
            () => {},
        );
        if (!uploadRes.success) {
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return new ErrOption(
                uploadRes.result.message,
                UploadShareFileThunkError.FileUploadFailed,
            );
        }

        const serverFiles =
            (await dispatch(appActions.getServerFiles(folder))) ?? [];
        const serverFile = serverFiles.find((item) => item.name === fileBase);

        if (!serverFile) {
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('load_serverfiles_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return new ErrOption(
                `Uploaded file ${uploadPath} not found`,
                UploadShareFileThunkError.UploadedFileNotFound,
            );
        }

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.UploadFile,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('upload_finished_header'),
                    status: NotificationStatus.Success,
                }),
            );
            dispatch(setFilesToUpload([serverFile]));
        });

        return serverFile;
    };
};

export const createFolderThunk = ({
    path,
    folderName,
}: CreateFolderData): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.CreateFolder,
                message: translate('creating_item'),
            }),
        );

        const res = await webdavApi.createNewFolder(
            stubUrl,
            user,
            userId,
            path,
            folderName,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(path));
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(path));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('item_created'),
                    status: NotificationStatus.Success,
                }),
            );
        });
    };
};

export const shareServerFileByEmailThunk = (
    email: string,
    file: ServerFile,
    sharePermissions: SharePermissions,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const stubUrl = selectStubUrl(state);
        const user = selectUser(state);
        const translate = selectTranslate(state);

        if (!stubUrl || !user) {
            console.error('Error getting stubUrl or user', stubUrl, user);
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.EmailShare,
                message: translate('creating_share'),
            }),
        );

        const shareesRes = await nextcloudApi.searchSharees({
            requestConfig: {
                stubUrl,
                user,
            },
            searchTerm: email,
            itemType: ItemType.Folder,
            shareTypes: [ShareType.User],
        });

        if (!shareesRes.success) {
            dispatch(
                stopFetch({
                    operationId: OperationId.EmailShare,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const sharee = shareesRes.result.ocs.data.exact.users[0];

        if (!sharee) {
            dispatch(
                stopFetch({
                    operationId: OperationId.EmailShare,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const shareRes = await nextcloudApi.shareByPath({
            requestConfig: {
                stubUrl,
                user,
            },
            shareTargetPath: file.path,
            shareType: sharee.value.shareType,
            shareWith: sharee.value.shareWith,
            permissions: sharePermissions,
        });

        if (!shareRes.success) {
            dispatch(
                stopFetch({
                    operationId: OperationId.EmailShare,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const url = `${user.server}/index.php/f/${file.fileId}`;

        dispatch(stopFetch({ operationId: OperationId.EmailShare }));
        submitDialogResult(
            new EmailShareDialogResult({
                name: file.name,
                url,
            }),
        );
    };
};

export const searchUserIdsThunk = (
    query: string,
): ThunkAction<
    Promise<ReturnType<typeof nextcloudApi.searchUserIds>>,
    RootState,
    unknown,
    AnyAction
> => {
    return async function thunk(
        dispatch,
        getState,
    ): Promise<ReturnType<typeof nextcloudApi.searchUserIds>> {
        const state = getState();
        const queryToUserIdsResponse = selectQueryToUserIdsResponse(state);

        const userIdsResponse = queryToUserIdsResponse[query];
        if (userIdsResponse) {
            return userIdsResponse;
        }

        const stubUrl = selectStubUrl(state);
        const user = selectUser(state);

        if (!stubUrl || !user) {
            console.error('Error getting stubUrl or user', stubUrl, user);
            return {
                success: false,
                result: {
                    message: 'Error getting stubUrl or user',
                    errorCode: NextcloudAppErrorCode.nextcloudApi,
                },
            };
        }

        const accountIdsRes = await nextcloudApi.searchUserIds(
            stubUrl,
            user,
            query,
        );

        if (accountIdsRes.success) {
            dispatch(setUserIdsResponse(query, accountIdsRes));
        }

        return accountIdsRes;
    };
};

export const shareServerFileThunk = ({
    path,
    expirationDate,
    password,
}: {
    path: string;
    expirationDate?: Date | null;
    password?: string;
}): ThunkAction<
    Promise<OCSOkResponseBody<Share> | null>,
    RootState,
    unknown,
    AnyAction
> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const stubUrl = selectStubUrl(state);
        const user = selectUser(state);
        const translate = selectTranslate(state);
        if (!stubUrl || !user) {
            console.error('Error getting stubUrl or user', stubUrl, user);
            return null;
        }

        dispatch(
            startFetch({
                id: OperationId.ServerFileShare,
                message: translate('creating_share'),
            }),
        );

        const expirationDateStr = Utilities.formatUploadDate(expirationDate);
        const shareRes = await nextcloudApi.shareByPath({
            requestConfig: {
                stubUrl,
                user,
            },
            shareTargetPath: path,
            shareType: ShareType.PublicLink,
            permissions: SharePermissions.Read,
            expirationDate: expirationDateStr,
            password,
        });

        if (!shareRes.success) {
            dispatch(
                stopFetch({
                    operationId: OperationId.ServerFileShare,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return null;
        }

        dispatch(
            stopFetch({
                operationId: OperationId.ServerFileShare,
            }),
        );
        submitDialogResult(
            new ShareFileDialogResult({
                url: shareRes.result.ocs.data.url,
                password,
                expirationdate: expirationDateStr,
            }),
        );
        return shareRes.result;
    };
};

export const loadDndFilesThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return function thunk(dispatch, getState) {
        const state = getState();
        const translate = selectTranslate(state);
        const context = selectTeamsContext(getState());
        if (!context) {
            console.error('No teams context found');
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.LoadDndFiles,
                message: translate('prepare_accounts_fetch'),
            }),
        );
        microsoftTeams.thirdPartyCloudStorage.getDragAndDropFiles(
            context.chat.id,
            async (files, error) => {
                if (error) {
                    console.error('Error:', error);
                    dispatch(
                        stopFetch({
                            operationId: OperationId.LoadDndFiles,
                            message: 'Error loading files',
                            errorCode: AppErrorCode.CustomError,
                            status: NotificationStatus.Error,
                        }),
                    );
                    return;
                }

                const fileContentArr = await Promise.all(
                    files.map(async (file) => file.arrayBuffer()),
                );

                batch(() => {
                    dispatch(
                        setFilesToUpload(
                            mapPlainFilesToFileItems(
                                files as File[],
                                fileContentArr,
                            ),
                        ),
                    );
                    dispatch(
                        stopFetch({
                            operationId: OperationId.LoadDndFiles,
                        }),
                    );
                });
            },
        );
    };
};

export const shareDndFilesThunk = ({
    files,
    folder,
    password,
    expirationDate,
    onUploadProgress = () => {},
}: {
    files: FileItem[];
    folder: string;
    password?: string;
    expirationDate?: Date | null;
    onUploadProgress?: (progress: number) => void;
}): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        const isFolderCreated = await createShareFolders(folder);

        if (!isFolderCreated) {
            dispatch(
                showNotification({
                    message: translate('could_not_create_folder'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );
            return;
        }

        const requestConfig = {
            stubUrl,
            user,
            userId,
        } as const;

        const { uploadResponses, finalFilenames } = await uploadFilesInParallel(
            {
                files,
                folder,
                onUploadProgress,
                requestConfig,
            },
        );

        if (uploadResponses.some(isResponseError)) {
            console.error('uploadResults', uploadResponses);

            dispatch(
                showNotification({
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );

            if (uploadResponses.every(isResponseError)) {
                return;
            }
        }

        const expirationDateStr = Utilities.formatUploadDate(expirationDate);
        const uploadedFilenames = finalFilenames.filter(
            (filename, idx) => uploadResponses[idx].success,
        );
        const shareResponses = await Promise.all(
            uploadedFilenames.map((filename) =>
                nextcloudApi.shareByPath({
                    requestConfig,
                    shareTargetPath: `${folder}/${filename}`,
                    shareType: ShareType.PublicLink,
                    permissions: SharePermissions.Read,
                    password,
                    expirationDate: expirationDateStr,
                }),
            ),
        );

        if (shareResponses.some(isResponseError)) {
            console.error('shareResponses', shareResponses);

            dispatch(
                showNotification({
                    message: translate('share_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );

            if (shareResponses.every(isResponseError)) {
                return;
            }
        }

        const sharedFileInfoArr = [];
        for (let i = 0; i < shareResponses.length; i++) {
            const shareRes = shareResponses[i];
            if (!shareRes.success) {
                continue;
            }

            sharedFileInfoArr.push({
                name: uploadedFilenames[i],
                url: shareRes.result.ocs.data.url,
            });
        }

        submitDialogResult(
            new DndPublicShareDialogResult({
                sharedFileInfoArr,
                password,
                expirationDate: expirationDateStr,
            }),
        );

        if (shareResponses.some((res) => !res.success)) {
            dispatch(
                showNotification({
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );
        }
    };
};

export const emailShareDndFilesThunk = ({
    files,
    folder,
    email,
    permissions,
    onUploadProgress = () => {},
}: {
    files: FileItem[];
    folder: string;
    email: string;
    permissions: SharePermissions;
    onUploadProgress?: (progress: number) => void;
}): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        const shareesRes = await nextcloudApi.searchSharees({
            requestConfig: {
                stubUrl,
                user,
            },
            searchTerm: email,
            itemType: ItemType.Folder,
            shareTypes: [ShareType.User],
        });

        if (!shareesRes.success) {
            dispatch(
                showNotification({
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const sharee = shareesRes.result.ocs.data.exact.users[0];

        if (!sharee) {
            dispatch(
                stopFetch({
                    operationId: OperationId.EmailShare,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const isFolderCreated = await createShareFolders(folder);

        if (!isFolderCreated) {
            dispatch(
                showNotification({
                    message: translate('could_not_create_folder'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );
            return;
        }

        const requestConfig = {
            stubUrl,
            user,
            userId,
        } as const;

        const { uploadResponses, finalFilenames } = await uploadFilesInParallel(
            {
                files,
                folder,
                onUploadProgress,
                requestConfig,
            },
        );

        if (uploadResponses.some(isResponseError)) {
            console.error('uploadResults', uploadResponses);
            dispatch(
                showNotification({
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );

            if (uploadResponses.every(isResponseError)) {
                return;
            }
        }

        const uploadedFilenames = finalFilenames.filter(
            (filename, idx) => uploadResponses[idx].success,
        );
        const shareResponses = await Promise.all(
            uploadedFilenames.map((filename) =>
                nextcloudApi.shareByPath({
                    shareTargetPath: `${folder}/${filename}`,
                    shareType: sharee.value.shareType,
                    shareWith: sharee.value.shareWith,
                    permissions: permissions,
                    requestConfig,
                }),
            ),
        );

        if (shareResponses.some(isResponseError)) {
            console.error('shareResponses', shareResponses);
            dispatch(
                showNotification({
                    message: translate('share_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );

            if (shareResponses.every(isResponseError)) {
                return;
            }
        }

        const sharedFileInfoArr: SharedFileInfo[] = [];
        for (let i = 0; i < shareResponses.length; i++) {
            const shareRes = shareResponses[i];
            if (!shareRes.success) {
                continue;
            }

            sharedFileInfoArr.push({
                name: uploadedFilenames[i],
                // eslint-disable-next-line max-len
                url: `${user.server}/index.php/f/${shareRes.result.ocs.data.file_source}`,
            });
        }

        submitDialogResult(
            new DndEmailShareDialogResult({
                sharedFileInfoArr,
            }),
        );

        if (shareResponses.some(isResponseError)) {
            dispatch(
                showNotification({
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                }),
            );
        }
    };
};
