import { ValueOf } from '../helpers/utilityTypes';
import { appConstants } from './actions/app/actionTypes';
import { authConstants } from './actions/auth/actionTypes';
import { ConversationActionType } from './actions/conversation/actionTypes';
import { MsalActionType } from './actions/msal/actionTypes';
import { uiConstants } from './actions/ui/actionTypes';

export type ReduxActionType =
    | ValueOf<typeof appConstants>
    | ValueOf<typeof authConstants>
    | ValueOf<typeof uiConstants>
    | MsalActionType
    | ConversationActionType;

export type ReduxAction<Type extends ReduxActionType, Payload = undefined> = {
    type: Type;
    payload: Payload;
};

export const createReduxAction = <
    Type extends ReduxActionType,
    Payload = undefined,
>(
    type: Type,
    payload?: Payload,
): ReduxAction<Type, Payload> => ({
    type,
    payload,
});
