import { useContext } from 'react';
import { WizardContextValue } from './wizard.model';
import { WizardContext } from './context';

export const useWizard = (): WizardContextValue => {
    const contextValue = useContext(WizardContext);

    if (!contextValue) {
        throw new Error(
            `${useWizard.name} must be used within a wizard's context provider`,
        );
    }

    return contextValue;
};
