import React, { FormEvent, MouseEvent } from 'react';
import {
    TextField,
    Text,
    ITextFieldProps,
    ITextStyles,
    mergeStyles,
} from '@fluentui/react';

const textFieldStylesFunc = ({ disabled }: ITextFieldProps) => {
    return {
        suffix: {
            cursor: 'pointer',
            padding: '0 8px',
            background: disabled ? '#F3F2F1' : 'transparent',
        },
    };
};

const buttonClass = mergeStyles({
    all: 'unset',
    ':disabled': {
        cursor: 'not-allowed',
    },
});

const textStyles: ITextStyles = { root: { color: '#f36928' } };

type Props = Omit<ITextFieldProps, 'styles' | 'onRenderSuffix'> & {
    buttonText: string;
    onChange: (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => void;
    onButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const SideButtonTextField = ({
    disabled,
    buttonText,
    onButtonClick,
    ...rest
}: Props): JSX.Element => {
    return (
        <TextField
            disabled={disabled}
            styles={textFieldStylesFunc}
            onRenderSuffix={(_props, _render) => {
                return (
                    <button
                        disabled={disabled}
                        type="button"
                        className={buttonClass}
                        onClick={onButtonClick}
                    >
                        <Text styles={textStyles}>{buttonText}</Text>
                    </button>
                );
            }}
            {...rest}
        />
    );
};
