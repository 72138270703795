import React from 'react';
import { Stack, Text, Image } from '@fluentui/react';
import { appStyles } from '../../helpers';

type Props = {
    name?: string;
    emailAdress: string;
};

const GuestImage = () => {
    return <Image src="assets/name.png" width="30px" />;
};

export const Account = ({ name, emailAdress }: Props) => {
    return (
        <Stack
            horizontal
            horizontalAlign="stretch"
            style={{ width: '100%' }}
            tokens={{ childrenGap: 10 }}
        >
            <Stack style={{ width: '10%' }} verticalAlign="center">
                <GuestImage />
            </Stack>
            <Stack verticalAlign="center" style={{ width: '100%' }}>
                {!!name && (
                    <Text
                        className={appStyles.globalStyles.comment}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {' '}
                        {name}
                    </Text>
                )}
                <Text
                    className={appStyles.globalStyles.comment}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {' '}
                    {emailAdress}
                </Text>
            </Stack>
        </Stack>
    );
};
