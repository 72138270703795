import React from 'react';
import { Checkbox as FluentCheckbox, ICheckboxProps } from '@fluentui/react';

type Props = ICheckboxProps & {
    color?: string;
};

export const Checkbox = ({
    color = 'default',
    ...rest
}: Props): JSX.Element => {
    return (
        <FluentCheckbox
            styles={(styleProps) =>
                styleProps.checked &&
                color && {
                    checkbox: {
                        background: `${color} !important`,
                        borderColor: `${color} !important`,
                    },
                }
            }
            {...rest}
        />
    );
};
