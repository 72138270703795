import { app } from '@microsoft/teams-js';
import { ChatMember } from '../../../services/statusSync/msGraphApi';
import produce from 'immer';
import { ConversationActionType } from '../../actions/conversation/actionTypes';
import { ConversationAction } from '../../actions/conversation/actionCreators';
import {
    ApiResponse,
    OCSOkResponseBody,
    SearchUserIdsData,
} from 'nextcloud-api';

export enum ShareContext {
    Any = 'any',
    Organization = 'organization',
    Public = 'public',
}

type ConversationState = {
    teamsContext: app.Context | null;
    shareContext: ShareContext | null;
    chatMembers: ChatMember[];
    isExternalMember: boolean | null;
    queryToUserIdsResponse: Record<
        string,
        ApiResponse<OCSOkResponseBody<SearchUserIdsData>>
    >;
};

const initialState: ConversationState = {
    teamsContext: null,
    chatMembers: [],
    isExternalMember: null,
    shareContext: null,
    queryToUserIdsResponse: {},
};

export const conversationReducer = (
    state: ConversationState = initialState,
    action: ConversationAction,
): ConversationState => {
    switch (action.type) {
        case ConversationActionType.SetTeamsContext: {
            return produce(state, (draft) => {
                draft.teamsContext = action.payload;
            });
        }
        case ConversationActionType.SetShareContext: {
            return produce(state, (draft) => {
                draft.shareContext = action.payload.shareContext;
            });
        }
        case ConversationActionType.SetChatMembers: {
            return produce(state, (draft) => {
                draft.chatMembers = action.payload.chatMembers;
            });
        }
        case ConversationActionType.SetIsExternalMember: {
            return produce(state, (draft) => {
                draft.isExternalMember = action.payload.isExternalMember;
            });
        }
        case ConversationActionType.SetUserIdsResponse:
            return produce(state, (draft) => {
                const { query, userIdsResponse } = action.payload;
                draft.queryToUserIdsResponse[query] = userIdsResponse;
            });
        default:
            const _exhaustiveCheck: never = action;
            return state;
    }
};
