import { AuthenticationResult } from '@azure/msal-browser';
import produce from 'immer';
import { MsalAction } from '../../actions/msal/actions';
import { MsalActionType } from '../../actions/msal/actionTypes';

type SerializableAuthenticationResult = Omit<
    AuthenticationResult,
    'expiresOn' | 'extExpiresOn'
> & {
    expiresOn: number | null;
    extExpiresOn: number;
};

const createSerializableAuthenticationResult = (
    authRes: AuthenticationResult,
): SerializableAuthenticationResult => {
    return {
        ...authRes,
        expiresOn: authRes.expiresOn?.getTime() ?? null,
        extExpiresOn: authRes.extExpiresOn.getTime(),
    };
};

type IntialState = {
    authResult: SerializableAuthenticationResult | null;
    showMsLoginDialog: boolean;
};

const initialState: IntialState = {
    authResult: null,
    showMsLoginDialog: false,
};

export const msalReducer = (
    state: IntialState = initialState,
    action: MsalAction,
) => {
    switch (action.type) {
        case MsalActionType.SetAuthResult: {
            return produce(state, (draft) => {
                if (!action.payload) {
                    draft.authResult = null;
                    return;
                }
                draft.authResult = createSerializableAuthenticationResult(
                    action.payload,
                );
            });
        }
        case MsalActionType.SetShowDialog: {
            return produce(state, (draft) => {
                draft.showMsLoginDialog = action.payload.showMsLoginDialog;
            });
        }
        default:
            const _exhaustiveCheck: never = action;
    }
    return state;
};
