import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Text,
    Stack,
    IconButton,
    IStackTokens,
    IStackStyles,
    IButtonStyles,
    IIconProps,
} from '@fluentui/react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { appActions } from '../../../store/actions/app/actions';
import { selectTranslate } from '../../../store/selectors/ui';
import { useWizard } from '../../../wizard';
import { ServerFilesDialog } from '../../dialogs';
import WizardFiles from '../files';
import { PageHeading } from '../../common/pageHeading';
import { WizardFooter } from '../footer';
import {
    selectDestinationFolder,
    selectFilesToUpload,
} from '../../../store/selectors/app';
import { loadDndFilesThunk } from '../../../store/actions/app/thunks';
import { selectTeamsPathUploadFiles } from '../../../store/selectors/auth';

const containerTokens: IStackTokens = { childrenGap: 10 };

const textStyle: CSSProperties = {
    fontSize: 14,
    fontStyle: 'normal',
    marginTop: 0,
};

const uploadContainerStyles: IStackStyles = { root: { alignItems: 'center' } };
const uploadButtonContainerTokens: IStackTokens = { childrenGap: 8 };

const folderIconButtonStyles: IButtonStyles = {
    root: { color: '#F36928 !important' },
};

const folderIconProps: IIconProps = {
    iconName: 'FabricFolder',
};

const wizardFilesContainerTokens: IStackTokens = { childrenGap: 15 };

export const DndFilesStep = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const translate = useAppSelector(selectTranslate);
    const filesToUpload = useAppSelector(selectFilesToUpload);
    const destinationFolder = useAppSelector(selectDestinationFolder);
    const defaultUploadFolder = useAppSelector(selectTeamsPathUploadFiles);

    const [foldersDialogHidden, setFoldersDialogHidden] = useState(true);
    const { setWizardStep } = useWizard();

    const uploadPath = destinationFolder
        ? destinationFolder.path
        : defaultUploadFolder;

    useEffect(() => {
        if (filesToUpload.length > 0) {
            return;
        }
        dispatch(loadDndFilesThunk());
    }, [filesToUpload.length]);

    const onDestinationClick = (): void => {
        dispatch(appActions.getServerFiles(''));
        setFoldersDialogHidden(false);
    };

    return (
        <>
            <Stack tokens={containerTokens}>
                <PageHeading text="Share files" />
                <Text style={textStyle}>{translate('sharefiles_desc')}</Text>
                <Stack
                    horizontal={true}
                    styles={uploadContainerStyles}
                    tokens={uploadButtonContainerTokens}
                >
                    <IconButton
                        styles={folderIconButtonStyles}
                        iconProps={folderIconProps}
                        onClick={onDestinationClick}
                    />
                    <Text>{uploadPath}</Text>
                </Stack>
                <Stack tokens={wizardFilesContainerTokens}>
                    <WizardFiles
                        wizardPos={-1}
                        onEdit={setWizardStep}
                        onDestination={onDestinationClick}
                        hideDestinationFolder={true}
                    />
                </Stack>
            </Stack>
            <WizardFooter />
            <ServerFilesDialog
                hidden={foldersDialogHidden}
                onDismiss={setFoldersDialogHidden}
                renderFiles={false}
            />
        </>
    );
};
