import React, { FC, useEffect } from 'react';
import { useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { GuardFallback } from './model';
// eslint-disable-next-line max-len
import { getConversationContextThunk } from '../store/actions/conversation/thunks';
import { selectShareContext } from '../store/selectors/conversation';

type Props = {
    fallback?: GuardFallback;
    children: JSX.Element;
};

export const ConversationContextGuard = ({
    children,
    fallback,
}: Props): JSX.Element | null => {
    const dispatch = useDispatch();
    const shareContext = useAppSelector(selectShareContext);

    useEffect(() => {
        if (shareContext) {
            return;
        }

        dispatch(getConversationContextThunk());
    }, []);

    if (!shareContext) {
        if (!fallback) {
            return null;
        }

        const element = typeof fallback === 'function' ? fallback() : fallback;

        return element ?? null;
    }

    return children;
};

export const withConversationContextGuard = <T extends Record<string, unknown>>(
    component: FC<T>,
    fallback?: GuardFallback,
): FC<T> =>
    function WithConversationContextGuard(props) {
        return (
            <ConversationContextGuard fallback={fallback}>
                {React.createElement(component, props, props?.children)}
            </ConversationContextGuard>
        );
    };
