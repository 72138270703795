import {
    ApiResponse,
    NextcloudRequestConfig,
    ResponseError,
    webdavApi,
} from 'nextcloud-api';
import { getRepeatedFileName } from '../helpers/file';
import { FileItem } from '../model';

export const countFilenameRepeats = async ({
    filename,
    folder,
    initialRepeatCount,
    requestConfig: { stubUrl, user, userId },
}: {
    filename: string;
    folder: string;
    initialRepeatCount: number;
    requestConfig: NextcloudRequestConfig & { userId: string };
}): Promise<number> => {
    let newName =
        initialRepeatCount > 1
            ? getRepeatedFileName(filename, initialRepeatCount + 1)
            : filename;
    let fileRepeatCount = initialRepeatCount;
    let fileExists = true;

    do {
        const result = await webdavApi.exists(
            stubUrl,
            user,
            userId,
            folder + '/' + newName,
        );

        fileExists = result.success && result.result;
        if (!fileExists) {
            continue;
        }

        newName = getRepeatedFileName(filename, ++fileRepeatCount);
    } while (fileExists);

    return fileRepeatCount;
};

type UploadFilesArgs = {
    files: FileItem[];
    folder: string;
    requestConfig: NextcloudRequestConfig & { userId: string };
    onUploadProgress?: (progress: number) => void;
};

export const uploadFilesInParallel = async ({
    files,
    folder,
    onUploadProgress = () => {},
    requestConfig,
}: UploadFilesArgs): Promise<{
    uploadResponses: (ApiResponse | ResponseError)[];
    finalFilenames: string[];
}> => {
    const finalFilenames: string[] = [];
    const uploadResponses = await Promise.all(
        files.map(async (file) => {
            const fileExistsRes = await webdavApi.exists(
                requestConfig.stubUrl,
                requestConfig.user,
                requestConfig.userId,
                `${folder}/${file.name}`,
            );

            if (!fileExistsRes.success) {
                finalFilenames.push(file.name);
                return fileExistsRes;
            }

            let newFilename = file.name;
            if (fileExistsRes.result) {
                const filenameRepeatCount = await countFilenameRepeats({
                    filename: file.name,
                    folder,
                    initialRepeatCount: 1,
                    requestConfig,
                });
                newFilename = getRepeatedFileName(
                    file.name,
                    filenameRepeatCount,
                );
            }
            finalFilenames.push(newFilename);

            return webdavApi.copyOrCreateFile(
                requestConfig.stubUrl,
                requestConfig.user,
                requestConfig.userId,
                newFilename,
                `${folder}/${newFilename}`,
                file.content,
                onUploadProgress,
            );
        }),
    );

    return { uploadResponses, finalFilenames };
};
