import { z } from 'zod';
import { store } from '../../store';
import { selectFileSharingSettings } from '../../store/selectors/auth';
import { selectTranslate } from '../../store/selectors/ui';
import { validationApi } from '../../services/validationApi';
import { selectFilesToUpload } from '../../store/selectors/app';

export const passwordRefinement = async (
    password: string,
    context: z.RefinementCtx,
): Promise<void> => {
    const errorMessage = await validationApi.validatePassword(password);

    if (!errorMessage) {
        return z.NEVER;
    }

    context.addIssue({
        code: z.ZodIssueCode.custom,
        message: errorMessage,
    });
};

export const expirationDateRefinement = (
    date: Date | undefined | null,
    context: z.RefinementCtx,
): void => {
    const state = store.getState();
    const filesSharingSettings = selectFileSharingSettings(state);
    if (!filesSharingSettings) {
        return z.NEVER;
    }

    const translate = selectTranslate(state);

    if (date || !filesSharingSettings.ExpireDateEnforced) {
        return z.NEVER;
    }

    context.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate('share_nodate'),
    });
};

export const destinationFolderRefinement = (
    destinationFolder: { content?: ArrayBuffer } | null,
    context: z.RefinementCtx,
): void => {
    const state = store.getState();
    const files = selectFilesToUpload(state);
    const translate = selectTranslate(state);

    const isLocalFile = Boolean(files[0]?.content);
    const isValidDestinationFolder = isLocalFile && destinationFolder;

    if (isValidDestinationFolder || !isLocalFile) {
        return z.NEVER;
    }

    context.addIssue({
        code: z.ZodIssueCode.custom,
        message: translate('main_page_no_dest_folder'),
    });
};
