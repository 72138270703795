import { SortDirection } from '@tanstack/react-table';
import { PathPart } from '../components/common/breadcrumbs';
import { ServerFile } from '../model';
import * as path from 'path';
import { storeKeys } from './storage';

export const sortFilesDirectoriesFirstInAlphabeticalOrder = (
    a: ServerFile,
    b: ServerFile,
) => {
    if (a.type === 'directory' && b.type !== 'directory') {
        return -1;
    }
    if (a.type !== 'directory' && b.type === 'directory') {
        return 1;
    }
    if (a.type === b.type) {
        return a.name.localeCompare(b.name);
    }
    return 0;
};

const SIZE_POSTFIXES = ['KB', 'MB', 'GB', 'TB'] as const;

export const formatBytes = (bytes: number, decimals: number = 2): string => {
    let postfixIndex: number;
    let divisorBytes = 1024; // 1KB
    let convertedBytes = bytes;

    do {
        convertedBytes = bytes / divisorBytes;
        postfixIndex = typeof postfixIndex === 'number' ? postfixIndex + 1 : 0;
        divisorBytes *= 1024;
    } while (divisorBytes < bytes && postfixIndex < SIZE_POSTFIXES.length);

    return (
        convertedBytes.toFixed(decimals).toString() +
        SIZE_POSTFIXES[postfixIndex]
    );
};

export const cutSortedPathPartsTill = (
    pathParts: PathPart[],
    path: string,
): PathPart[] => {
    const newPathToSelected = [];
    for (const pathPart of pathParts) {
        if (pathPart.path.length > path.length) {
            break;
        }
        newPathToSelected.push(pathPart);
    }
    return newPathToSelected;
};

export const getSortingTitle = (
    nextDirection: false | SortDirection,
): string => {
    switch (nextDirection) {
        case 'asc':
            return 'Sort ascending';
        case 'desc':
            return 'Sort descending';
        default:
            return 'Clear sort';
    }
};

export const getRenamedFilePath = (
    initialFilePath: string,
    newFilename: string,
): string => {
    const parsedPath = path.parse(initialFilePath);
    return path.format({
        ...parsedPath,
        base: undefined,
        name: newFilename,
    });
};

export const isNonHiddenFile = (file: ServerFile): boolean =>
    !file.name.startsWith('.');

export const getStartingPathParts = (): PathPart[] | null => {
    const pathParts = localStorage.getItem(storeKeys.FILE_EXPLORER_PATH);
    if (!pathParts) {
        return null;
    }
    try {
        const parsedPathParts = JSON.parse(pathParts);
        if (Array.isArray(parsedPathParts)) {
            return parsedPathParts;
        }
    } catch (err) {
        console.error(err);
        return null;
    }
    return null;
};
