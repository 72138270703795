import React, { FormEvent, MouseEvent, useState } from 'react';
import { IStackTokens, ITextStyles, Stack, Text } from '@fluentui/react';
import { Checkbox } from '../components/common/checkbox';
import { InternalLinkSection } from '../components/internalLinkSection';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useFormValidationDialog } from '../helpers/hooks';
import { useAppDispatch, useAppSelector } from '../store';
import { ProgressDialog, WarningDialog } from '../components/dialogs';
import { Logger } from '../helpers';
import { appActions } from '../store/actions/app/actions';
import {
    getIntialEmailShareValues,
    ShareByEmailData,
} from '../form-loaders/shareByEmail';
import { shareByEmailScehma } from '../schemas/shareByEmail';
// eslint-disable-next-line max-len
import { ControlledTextField } from '../components/form/controls/controlledTextField';
import { SharePermissions } from 'nextcloud-api';
import { selectTranslate } from '../store/selectors/ui';
import { WizardFooter } from '../components/wizard/footer';
import {
    emailShareDndFilesThunk,
    shareServerFileByEmailThunk,
} from '../store/actions/app/thunks';
import { selectDestinationFolder } from '../store/selectors/app';
import {
    addUploadedSize,
    setProgressSize,
} from '../store/actions/app/actionCreators';
import { reduceFileSize } from '../helpers/file';
import { selectTeamsPathUploadFiles } from '../store/selectors/auth';

const containerTokens: IStackTokens = { childrenGap: 20 };

const pageDescriptionTextStyles: ITextStyles = {
    root: { lineHeight: 20, color: '#98908C' },
};

const emailContainerTokens: IStackTokens = { childrenGap: 8 };

export enum ShareTypePageVariant {
    Regular = 'regular',
    Dnd = 'dnd',
}

export type OrganizationSharePageProps = {
    variant: ShareTypePageVariant;
};

export const OrganizationSharePage = ({
    variant,
}: OrganizationSharePageProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const translate = useAppSelector(selectTranslate);
    const destinationFolder = useAppSelector(selectDestinationFolder);
    const defaultUploadFolder = useAppSelector(selectTeamsPathUploadFiles);

    const {
        watch,
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ShareByEmailData>({
        defaultValues: getIntialEmailShareValues(),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: zodResolver(shareByEmailScehma),
        criteriaMode: 'firstError',
    });

    const { errorToDisplay, showValidationDialog, setShowValidationDialog } =
        useFormValidationDialog(errors);

    const [progressDialogHidden, setProgressDialogHidden] = useState(true);

    const onUpload = async (data: ShareByEmailData, e: FormEvent) => {
        e.preventDefault();
        Logger.info('onUpload called');

        const { files, email, permissions } = data;

        if (variant === ShareTypePageVariant.Regular) {
            dispatch(shareServerFileByEmailThunk(email, files[0], permissions));
            return;
        }

        const uploadFolder = destinationFolder ?? defaultUploadFolder;
        const totalSize = files.reduce(reduceFileSize, 0);

        setProgressDialogHidden(false);
        dispatch(setProgressSize(totalSize));
        await dispatch(
            emailShareDndFilesThunk({
                email,
                files,
                folder: uploadFolder,
                permissions,
                onUploadProgress: (progress) =>
                    dispatch(addUploadedSize(progress)),
            }),
        );
    };

    const hideValidationDialog = () => setShowValidationDialog(false);

    const permissionsGranted = watch('permissions') === SharePermissions.Update;

    const onPermissionChange = (e: FormEvent<HTMLInputElement>): void => {
        const permissions = e.currentTarget.checked
            ? SharePermissions.Update
            : SharePermissions.Read;

        setValue('permissions', permissions);
    };

    const onInternalLinkClick = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        dispatch(appActions.shareLink(watch('files')));
    };

    return (
        <form onSubmit={handleSubmit(onUpload)}>
            <Stack tokens={containerTokens}>
                <Text variant="medium" styles={pageDescriptionTextStyles}>
                    {translate('organization_share_description')}
                </Text>
                <Stack tokens={emailContainerTokens}>
                    <ControlledTextField
                        label={translate('feedback_contactemail')}
                        control={control}
                        {...register('email')}
                    />
                    <Checkbox
                        color="#F36928"
                        checked={permissionsGranted}
                        label={translate('grant_update_permissions')}
                        onChange={onPermissionChange}
                    />
                </Stack>
                {variant === ShareTypePageVariant.Regular && (
                    <InternalLinkSection onClick={onInternalLinkClick} />
                )}
            </Stack>
            <WizardFooter />
            <WarningDialog
                hidden={!showValidationDialog}
                subText={errorToDisplay?.message}
                icon="ErrorBadge"
                color="red"
                onDismiss={hideValidationDialog}
            />
            {variant === ShareTypePageVariant.Dnd && (
                <ProgressDialog
                    hidden={progressDialogHidden}
                    onDismiss={setProgressDialogHidden}
                    title={translate('progress_header')}
                    subText={translate('progress_count')}
                />
            )}
        </form>
    );
};
